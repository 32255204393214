import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _774f894a = () => interopDefault(import('../pages/shared/privacy-policy/index.vue' /* webpackChunkName: "" */))
const _21f34b9e = () => interopDefault(import('../pages/shared/terms-conditions/index.vue' /* webpackChunkName: "" */))
const _0a35e415 = () => interopDefault(import('../pages/home/home.vue' /* webpackChunkName: "" */))
const _3671e4df = () => interopDefault(import('../pages/cornerstone/people-lookup.vue' /* webpackChunkName: "" */))
const _06807b22 = () => interopDefault(import('../pages/cornerstone/phone-lookup.vue' /* webpackChunkName: "" */))
const _51c0c6c8 = () => interopDefault(import('../pages/cornerstone/address-lookup.vue' /* webpackChunkName: "" */))
const _2c023586 = () => interopDefault(import('../pages/cornerstone/faq.vue' /* webpackChunkName: "" */))
const _53b679b7 = () => interopDefault(import('../pages/contact/contact.vue' /* webpackChunkName: "" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "" */))
const _14140b7a = () => interopDefault(import('../pages/searching/index.vue' /* webpackChunkName: "" */))
const _11d04168 = () => interopDefault(import('../pages/landing/no-results.vue' /* webpackChunkName: "" */))
const _6c5bc195 = () => interopDefault(import('../pages/optout/optout.vue' /* webpackChunkName: "" */))
const _7684cdb6 = () => interopDefault(import('../pages/optout/request.vue' /* webpackChunkName: "" */))
const _483270e2 = () => interopDefault(import('../pages/optout/confirmation.vue' /* webpackChunkName: "" */))
const _0393099c = () => interopDefault(import('../pages/trending/day.vue' /* webpackChunkName: "" */))
const _c49f2c5c = () => interopDefault(import('../pages/trending/index.vue' /* webpackChunkName: "" */))
const _97234e22 = () => interopDefault(import('../pages/top-full-names/index.vue' /* webpackChunkName: "" */))
const _683bfd2e = () => interopDefault(import('../pages/top-full-names/state.vue' /* webpackChunkName: "" */))
const _13944a14 = () => interopDefault(import('../pages/top-last-names/index.vue' /* webpackChunkName: "" */))
const _4b05d902 = () => interopDefault(import('../pages/top-last-names/alphabet.vue' /* webpackChunkName: "" */))
const _1c88a69c = () => interopDefault(import('../pages/top-last-names/letter.vue' /* webpackChunkName: "" */))
const _cbcfeee4 = () => interopDefault(import('../pages/top-last-names/states.vue' /* webpackChunkName: "" */))
const _abf90196 = () => interopDefault(import('../pages/top-last-names/state.vue' /* webpackChunkName: "" */))
const _966c8e52 = () => interopDefault(import('../pages/bucket/index.vue' /* webpackChunkName: "" */))
const _b419a706 = () => interopDefault(import('../pages/street-bucket/index.vue' /* webpackChunkName: "" */))
const _a2ecfd30 = () => interopDefault(import('../pages/street-bucket/street.vue' /* webpackChunkName: "" */))
const _dc790a4a = () => interopDefault(import('../pages/index/index.vue' /* webpackChunkName: "" */))
const _302c424a = () => interopDefault(import('../pages/seo/results.vue' /* webpackChunkName: "" */))
const _59bb7e7d = () => interopDefault(import('../pages/seo/profile.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/privacy-policy/",
    component: _774f894a,
    name: "privacy-policy"
  }, {
    path: "/terms-conditions/",
    component: _21f34b9e,
    name: "terms-conditions"
  }, {
    path: "/",
    component: _0a35e415,
    name: "home"
  }, {
    path: "/people-search/",
    component: _3671e4df,
    name: "cornerstone.people"
  }, {
    path: "/reverse-phone-lookup/",
    component: _06807b22,
    name: "cornerstone.phone-lookup"
  }, {
    path: "/reverse-address-lookup/",
    component: _51c0c6c8,
    name: "cornerstone.address-lookup"
  }, {
    path: "/frequently-asked-questions/",
    component: _2c023586,
    name: "cornerstone.faq"
  }, {
    path: "/contact-us/",
    component: _53b679b7,
    name: "contact"
  }, {
    path: "/login/",
    component: _cad79e9c,
    name: "login"
  }, {
    path: "/find/:landingID/",
    component: _14140b7a,
    name: "flow.searching"
  }, {
    path: "/found/:landingID/",
    component: _11d04168,
    name: "flow.results"
  }, {
    path: "/purge-my-data/",
    component: _6c5bc195,
    name: "optout"
  }, {
    path: "/purge-my-data/request/",
    component: _7684cdb6,
    name: "optout-request"
  }, {
    path: "/purge-my-data/confirm/:token/",
    component: _483270e2,
    name: "optout-confirmation"
  }, {
    path: "/trending-names/:year/:month/:day/",
    component: _0393099c,
    name: "trending.day"
  }, {
    path: "/trending-names/:year?/",
    component: _c49f2c5c,
    name: "trending"
  }, {
    path: "/full-names/",
    component: _97234e22,
    name: "top-full-names.root"
  }, {
    path: "/full-names/:state/",
    component: _683bfd2e,
    name: "top-full-names.state"
  }, {
    path: "/last-names/",
    component: _13944a14,
    name: "top-last-names.root"
  }, {
    path: "/last-names/letter/",
    component: _4b05d902,
    name: "top-last-names.alphabet"
  }, {
    path: "/last-names/letter/:letter/",
    component: _1c88a69c,
    name: "top-last-names.letter"
  }, {
    path: "/last-names/state/",
    component: _cbcfeee4,
    name: "top-last-names.states"
  }, {
    path: "/last-names/state/:state/",
    component: _abf90196,
    name: "top-last-names.state"
  }, {
    path: "/names-directory/:letter/",
    component: _966c8e52,
    name: "bucket.letter"
  }, {
    path: "/names-directory/:letter/:tier2/",
    component: _966c8e52,
    name: "bucket.tier2"
  }, {
    path: "/names-directory/:letter/:tier2/:tier3/",
    component: _966c8e52,
    name: "bucket.tier3"
  }, {
    path: "/directory/states",
    component: _b419a706,
    name: "address.root"
  }, {
    path: "/directory/states/:state",
    component: _b419a706,
    name: "address.cities"
  }, {
    path: "/directory/states/:state/:city",
    component: _a2ecfd30,
    name: "address.zipcodes"
  }, {
    path: "/directory/states/:state/:city/:zip/:page(\\d+)?",
    component: _a2ecfd30,
    name: "address.streets"
  }, {
    path: "/directory/states/:state/:city/:zip/:street/:page(\\d+)?",
    component: _a2ecfd30,
    name: "address.streetnames"
  }, {
    path: "/directory/:letter/:page?",
    component: _dc790a4a,
    name: "full-names-letter"
  }, {
    path: "/phone-number/:phone/:page(\\d+)?",
    component: _302c424a,
    name: "seo.phone"
  }, {
    path: "/location/:state/:city/:street/:page(\\d+)?",
    component: _302c424a,
    name: "seo.address"
  }, {
    path: "/:lastName/:firstName/:page(\\d+)?",
    component: _302c424a,
    name: "seo.root"
  }, {
    path: "/:lastName/:firstName/:state/:page(\\d+)?",
    component: _302c424a,
    name: "seo.state"
  }, {
    path: "/:lastName/:firstName/:state/:city/:page(\\d+)?",
    component: _302c424a,
    name: "seo.city"
  }, {
    path: "/:lastName/:firstName/:state/:city/:uuid/",
    component: _59bb7e7d,
    name: "seo.profile"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
