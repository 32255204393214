/**
 * Trending Names Redirect Middleware
 * @param route
 * @param redirect
 */
export default function({ route, redirect }) {
  const { params } = route;
  const currentYear = new Date().getFullYear();
  const minYear = 1999;
  const redirectUrl = '/trending-names/';

  if (
    isNaN(params.year) ||
    params.year > currentYear ||
    params.year < minYear
  ) {
    redirect(redirectUrl);
  }
}
