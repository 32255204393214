import createPersistedState from 'vuex-persistedstate';
import cookie from 'cookie';

const createStorage = (req, res, expirationDays) => {
  const setCookieOpts = {
    maxAge: expirationDays * 24 * 60 * 60,
    path: '/',
    sameSite: 'lax'
  };
  const removeCookieOpts = {
    maxAge: -1,
    path: '/'
  };
  if (process.server) {
    return {
      getItem: key => {
        if (req.headers.cookie) {
          const parsedCookies = cookie.parse(req.headers.cookie);
          return parsedCookies[key] || null;
        }
        return null;
      },
      setItem: (key, value) => {
        res.setHeader(
          'Set-Cookie',
          cookie.serialize(key, value, setCookieOpts)
        );
      },
      removeItem: key => {
        res.setHeader(
          'Set-Cookie',
          cookie.serialize(key, '', removeCookieOpts)
        );
      }
    };
  }
  return {
    getItem: key => {
      const cookies = cookie.parse(document.cookie || '');
      return cookies[key] || null;
    },
    setItem: (key, value) => {
      document.cookie = cookie.serialize(key, value, setCookieOpts);
    },
    removeItem: key => {
      document.cookie = cookie.serialize(key, '', removeCookieOpts);
    }
  };
};

export default ({ $config, store, req, res }) => {
  const cookiePrefix = `${$config.website}-`;

  const persistConfig = [{ key: 'location', expirationDays: 1 }];

  persistConfig.forEach(({ key, expirationDays }) => {
    const fullKey = `${cookiePrefix}${key}`;
    createPersistedState({
      key: fullKey,
      paths: [key],
      storage: createStorage(req, res, expirationDays)
    })(store);
  });
};
