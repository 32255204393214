import getOffsetCount from '@/assets/js/shared/helpers/getOffsetCount';
import getSeoLink from './getSeoLink';

/**
 * @param {object} opts
 * @param {array} opts.peopleResponse
 * @param {array} opts.locationList
 * @returns {{locationList: any, searchFilterStateList: any[]}}
 */
const setLocationList = (opts = {}) => {
  if (!opts || !opts.peopleResponse || !opts.locationList) {
    return [];
  }

  let stateList = [];
  let searchFilterStateList = [];
  let stateCount = {};

  opts.peopleResponse.forEach(function(person) {
    if (!stateList.includes(person.state)) {
      stateList.push(person.state);
      stateCount[person.state] = 1;
    } else {
      stateCount[person.state] += 1;
    }
  });

  for (let i = 0; i < opts.locationList.length; i++) {
    if (!opts.locationList[i].state_full) {
      continue;
    }

    opts.locationList[i]['name'] = opts.locationList[i].state_full;
    const urlValue = getSeoLink({
      params: {
        state: opts.locationList[i].state
      },
      seoType: 'state'
    });
    let count = !opts.locationList[i].offset
      ? getOffsetCount({ count: opts.locationList[i].count })
      : opts.locationList[i].count;

    searchFilterStateList.push({
      displayName: opts.locationList[i].state_full,
      urlValue,
      count,
      offset: true
    });
  }

  return {
    locationList: opts.locationList,
    searchFilterStateList
  };
};

export default setLocationList;
