import getRandomItems from './getRandomItems';
/**
 * Returns Crime data for the crime component
 * @param {object} opts
 * @param {object} opts.crimes
 * @param {number} [opts.limit]
 * @param {boolean} [opts.onlyCrimes]
 * @returns {{criminalRecordsSexOffenderCount, criminalRecords}}
 */
const setCrimes = (opts = {}) => {
  if (!opts || !opts.crimes) {
    return null;
  }

  const { crimes, limit = 3, onlyCrimes = true } = opts;
  // crimes, limit = 3, onlyCrimes = true
  let criminalRecords = [];
  let criminalRecordsSexOffenderCount = 0;
  for (let i = 0; i < crimes.length; i++) {
    const personCrimes = crimes[i].crimes || [];
    let isSexOffender = false;

    for (let crime = 0; crime < personCrimes.length; crime++) {
      const { sexoffender, crimetype, description } = personCrimes[crime];
      if (sexoffender) {
        isSexOffender = true;
      }

      if (!crimetype && !description) {
        continue;
      }

      if (onlyCrimes) {
        criminalRecords.push(personCrimes[crime]);
      } else {
        const person = {
          firstname: crimes[i].firstname,
          middlename: crimes[i].middlename,
          lastname: crimes[i].lastname,
          crime: personCrimes[crime]
        };
        criminalRecords.push(person);
      }
    }

    if (isSexOffender) {
      criminalRecordsSexOffenderCount++;
    }
  }
  // Randomly select limited crimes
  const limitedCrimes = getRandomItems({
    stuff: criminalRecords,
    limit: limit
  });

  return { limitedCrimes, criminalRecordsSexOffenderCount };
};
export default setCrimes;
