import encodeCID from '../shared/helpers/encodeCID';
import setTitleCase from '../shared/helpers/setTitleCase';
import formatCity from '../shared/helpers/formatCity';
import getRegex from '../shared/helpers/getRegex';
/**
 *
 * @param {object} opts
 * @param {object} opts.person
 * @param {object} opts.query
 * @returns {string}
 */
const formatEverFlowLink = (opts = {}) => {
  const { person, query } = opts;
  const cid = person.cid.includes('-')
    ? encodeCID({ cid: person.cid })
    : person.cid;
  const firstNameParam = setTitleCase({ text: person.firstname });
  const lastNameParam = setTitleCase({ text: person.lastname });
  const stateParam = person.state ? person.state.toUpperCase() : '';
  const cityParam = person.city
    ? formatCity({ city: person.city }).replace(
        getRegex({ regexType: 'spaceRegex' }),
        '+'
      )
    : '';
  const queryObj = {
    ...query,
    sub1: '3',
    sub2: 'USPS',
    providerID: cid,
    firstName: firstNameParam,
    lastName: lastNameParam,
    state: stateParam,
    city: cityParam
  };
  const queryParams = Object.keys(queryObj);
  const queryString = queryParams
    .map(key => key + '=' + queryObj[key])
    .join('&');

  return `https://www.chkppl.com/cmp/NHNQ1/MND7Z/?${queryString}`;
};

export default formatEverFlowLink;
