const NBars = require('nbars/commonjs.js');

/**
 *
 * @param {object} opts
 * @param {object} opts.content
 * @param {string} opts.firstName
 * @param {string} opts.lastName
 * @returns {string}
 */
const setNbarsFullName = (opts = {}) => {
  if (!opts || !opts.content || !opts.firstName || !opts.lastName) {
    return '';
  }
  return NBars.transform(opts.content, {
    fullName: `${opts.firstName} ${opts.lastName}`
  });
};

export default setNbarsFullName;
