const NBars = require('nbars/commonjs.js');
import getGeneralLink from './getGeneralLink';

/**
 *
 * @param {object} opts
 * @param {number} opts.numberOfDays
 * @param {string} opts.labelFormat
 * @param {string} opts.urlFormat
 * @param {object} opts.router
 * @returns {array}
 */
const getLastXDays = (opts = {}) => {
  let dateObjs = [];
  for (let i = 1; i < opts.numberOfDays + 1; i++) {
    const today = new Date();
    const priorDate = new Date(
      new Date().setDate(today.getDate() - i)
    ).toLocaleDateString(undefined, {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    }); // returns MM/DD/YYYY
    const mm = priorDate.split('/')[0];
    const m = priorDate.split('/')[0].replace(/^0/, '');
    const dd = priorDate.split('/')[1];
    const d = priorDate.split('/')[1].replace(/^0/, '');
    const yyy = priorDate.split('/')[2];

    const dateEntry = {
      label: NBars.transform(opts.labelFormat, { mm, m, dd, d, yyy }),
      class_name: 'tz-footer__item--dates',
      url: getGeneralLink({
        router: opts.router,
        params: {
          year: yyy,
          month: m,
          day: d
        },
        routeType: 'trending.day'
      })
    };

    dateObjs.push(dateEntry);
  }
  return dateObjs;
};
export default getLastXDays;
