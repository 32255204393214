var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('wl-header',{class:{
      'wl-header--mobile-search': _vm.mobileSearchActive,
      'wl-header--home': _vm.isHome || _vm.isCornerStone,
      'wl-header--search-results': _vm.isSearchResults,
      'wl-header--seo': _vm.isSeoSearch,
      'wl-header--letter': _vm.isLetterPage,
      'wl-header--landing-alt': _vm.isLandingResultsAlt
    },attrs:{"id":"header","data-information-page":"","logo":_vm.header.logo,"logo-standard-size":_vm.logoSize,"logo-small":_vm.header.logo_small,"logo-small-size":_vm.logoSmallSize,"logo-name":_vm.header.name,"logo-title":_vm.header.title,"menu":_vm.showFullToggleMenu() ? [] : _vm.getTransormedDesktopMenu,"path":_vm.path,"hide-menu":_vm.isLanding,"show-aux-menu":!_vm.showFullToggleMenu() && !_vm.isLanding,"is-fixed":_vm.isFixed},scopedSlots:_vm._u([(!_vm.isLanding)?{key:"header-bar",fn:function(){return [_c('button',{directives:[{name:"touch",rawName:"v-touch:tap.prevent",value:(_vm.mobileSearchOpen),expression:"mobileSearchOpen",arg:"tap",modifiers:{"prevent":true}}],class:`${_vm.prefix}search-toggle`,attrs:{"type":"button","id":"searchToggle","aria-controls":"searchBar","aria-expanded":"false","aria-label":"Toggle Search"}},[_c('img',{class:`${_vm.prefix}search-toggle__icon`,attrs:{"src":_vm.header.mobile_search_icon,"alt":"Search Icon","loading":"lazy","width":"24px","height":"24px"}})]),_vm._v(" "),(_vm.showDesktopSearchBar())?_c('tz-search',{attrs:{"in-header":true,"prefill-fields":_vm.prefillFields,"fallback-fields":_vm.fallbackFields,"phone-link":_vm.phoneURL,"people-link":_vm.peopleURL,"search-icon":_vm.header.mobile_search_icon,"no-query-string":true,"default-state-label":_vm.header.default_state_option_label,"people-invalid-regex":_vm.getRegex({ regexType: 'peopleInvalidRegex' })},on:{"tz-search-override-redirect":_vm.navigateToSeo}}):_vm._e(),_vm._v(" "),_c('button',{directives:[{name:"touch",rawName:"v-touch:tap.prevent",value:(_vm.mobileSearchClose),expression:"mobileSearchClose",arg:"tap",modifiers:{"prevent":true}}],class:`${_vm.prefix}search-cancel`,attrs:{"type":"button","id":"searchClose"},domProps:{"textContent":_vm._s(`Cancel`)}}),_vm._v(" "),_c('ul',{staticClass:"wl-header__toggle-menu",attrs:{"id":"toggle-menu"}},_vm._l((_vm.showFullToggleMenu()
            ? _vm.header.menu
            : _vm.header.menu.slice(_vm.menuLinksDesktopCount)),function(link){return _c('li',{key:link.label,staticClass:"wl-header__toggle-menu-item"},[_c('a',{staticClass:"wl-header__toggle-menu-link",class:{
              'wl-header__toggle-menu-link--active': _vm.isLinkActive({ link })
            },attrs:{"href":_vm.getLink({ link }),"role":"button","aria-haspopup":"true","aria-expanded":"false"},domProps:{"textContent":_vm._s(link.label)}})])}),0)]},proxy:true}:(_vm.isLanding)?{key:"header-bar",fn:function(){return [_c('wl-trust-badges')]},proxy:true}:null],null,true)}),_vm._v(" "),_c('main',{ref:"insideContent",staticClass:"main",class:{
      'main--password': _vm.isPasswordPage,
      'main--landing-alt': _vm.isLandingResultsAlt,
      'main--home': _vm.isHome || _vm.isCornerStone,
      'main--searching': _vm.isLandingSearch,
      'main--optout': _vm.isOptout
    }},[_c('nuxt',{ref:"page",attrs:{"prefill-fields":_vm.prefillFields,"fallback-fields":_vm.fallbackFields}}),_vm._v(" "),_c('tz-footer',{class:{
        'tz-footer--landing': _vm.isLanding
      },attrs:{"alpha-base-link":"/names-directory/","display-sections":_vm.isLanding
          ? ['globalLinks', 'disclaimer']
          : [
              'alphaSelect',
              'columnSecondary',
              'columnTertiary',
              'columnQuarternary'
            ],"footer":_vm.isLanding
          ? { ..._vm.footer, disclaimer: _vm.landingDisclaimer }
          : {
              ..._vm.footer,
              secondary_list: _vm.secondaryList,
              tertiary_list: _vm.tertiaryList,
              quarternary_list: _vm.quarternaryList
            },"headerFixed":_vm.isFixed,"page":_vm.page,"has-trailing-slash":true,"is-alpha-lower":true,"state-link":"/directory/states/"}},[[_c('div',{staticClass:"tz-footer__column tz-footer__column--about"},[_c('a',{attrs:{"href":"/","aria-label":"Home"}},[_c('img',{staticClass:"tz-footer__logo",attrs:{"src":_vm.logo,"alt":"US People Search","height":"48","width":"104"}})]),_vm._v(" "),_c('p',{staticClass:"tz-footer__copy",domProps:{"textContent":_vm._s(_vm.footer.about)}})])]],2)],1),_vm._v(" "),_c('div',{directives:[{name:"touch",rawName:"v-touch:tap.self.stop",value:(_vm.closeHeaderModal),expression:"closeHeaderModal",arg:"tap",modifiers:{"self":true,"stop":true}}],class:`${_vm.prefix}scrim`},[_c('wl-modal-form',{class:{
        'wl-modal-form--seo-phone': _vm.isSeoPhone,
        'wl-modal-form--seo-address': _vm.isSeoAddress
      },attrs:{"open":true,"modal-title":_vm.getModalTitle(),"modal-text":_vm.getModalText(),"inputs":_vm.inputs,"confirm-button-text":_vm.header.modal.confirm_button_text,"cancel-button-text":_vm.header.modal.cancel_button_text,"close-modal":_vm.closeHeaderModal},on:{"wl-modal-input-focus":_vm.removeInputError,"wl-modal-form-confirm":_vm.navigateToPeopleFlow,"wl-modal-form-cancel":_vm.closeHeaderModal}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }