const NBars = require('nbars/commonjs.js');
import helpers from '@/assets/js/shared/resultHelper';
import getRegex from '@/assets/js/shared/helpers/getRegex';
import formatNameCaseWithSplitterAndSpacer from '@/assets/js/shared/helpers/formatNameCaseWithSplitterAndSpacer';
import formatPersonListAddresses from '@/assets/js/helpers/formatPersonListAddresses';
import setTitleCase from '@/assets/js/shared/helpers/setTitleCase';
import dashedPhoneNumber from '@/assets/js/shared/helpers/dashedPhoneNumber';
import getOffsetCount from '@/assets/js/shared/helpers/getOffsetCount';
import getCurrentResident from '@/assets/js/shared/helpers/getCurrentResident';
import formatPhoneNumber from '@/assets/js/shared/helpers/formatPhoneNumber';
import getCrimeData from '@/assets/js/shared/helpers/getCrimeData';
import setNbarsFullName from '@/assets/js/shared/helpers/setNbarsFullName';
import setLocationList from '@/assets/js/helpers/setLocationList';
import getRandomPhoneNumbers from '@/assets/js/shared/helpers/getRandomPhoneNumbers';
import queryWithSearchSDK from '@/assets/js/shared/helpers/queryWithSearchSDK';
import selectPeopleByOccupation from '@/assets/js/shared/helpers/selectPeopleByOccupation';
import encodeCID from 'assets/js/shared/helpers/encodeCID';
import formatCity from 'assets/js/shared/helpers/formatCity';
import getStateFromAbbr from '@/assets/js/shared/helpers/getStateFromAbbr';
import getSelectedLocationArray from '@/assets/js/shared/helpers/getSelectedLocationArray';
import getStateAbbrFromName from '@/assets/js/shared/helpers/getStateAbbrFromName';
import getSeoLink from '@/assets/js/helpers/getSeoLink';
import formatEverFlowLink from '@/assets/js/helpers/formatEverFlowLink';
const spaceRegEx = new RegExp(/' '|\+/, 'g');
const dashRegEx = new RegExp('-', 'g');
const PAGE_LIMIT = 25;
const peopleFullResponseFilter =
  'firstname,middlename,lastname,employers,addresses';
const phoneAddressesFilter =
  'firstname,middlename,lastname,dob,phones,addresses,id,cid';
let pageCms = {};
try {
  pageCms = require(`@/assets/cms/pages/results.json`);
} catch (err) {
  // Do nothing
}

const {
  question_age_variations: questionAgeItems,
  answer_age_variations: answerAgeItems,
  question_address_variations: questionAddrItems,
  answer_address_variations: answerAddrItems,
  question_phone_variations: questionPhoneItems,
  answer_phone_variations: answerPhoneItems,
  question_email_variations: questionEmailItems,
  answer_email_variations: answerEmailItems,
  question_relatives_variations: questionRelativesItems,
  answer_relatives_variations: answerRelativesItems,
  question_relationships_variations: questionRelationshipsItems,
  answer_relationships_variations: answerRelationshipsItems,
  question_money_variations: questionMoneyItems,
  answer_money_variations: answerMoneyItems
} = pageCms.qna;

const { contact_information: contactInfo, landing_id: landingId } = pageCms;

function getSeparatorData() {
  // Get symbols from the person card
  return {
    address: pageCms.person_card.separator_address,
    general: pageCms.person_card.separator_symbol,
    masking: pageCms.person_card.masking_symbol
  };
}
const symbols = getSeparatorData();
const resultHelper = {
  ...helpers,
  /**
   * buildPayload - Creates a bayload from a base and returns the build out object
   * @param {object} routeParams - route paramaters passed in from view
   * @param {object} route - the url broken up into pieces eg. query
   * @param {object} payloadBase - a baseline for building final payload, contains default values
   * @param {object} searchMethods - passed in query methods from the searchSDK
   * @param {string} userAgent - the browser/os of the entity requesting data
   * @param {string} flowType - tells the payload builder what path to take
   * @param {object} router
   * @param {object} $bugsnag - bugsnag instance
   * @returns {Promise<{}|*>} - a build out payload based on the flowType
   */
  buildPayload: async function(
    routeParams,
    route,
    payloadBase,
    searchMethods,
    userAgent,
    flowType,
    router,
    $bugsnag = {}
  ) {
    const queryHelper = this.queryWithSearchSDK;
    const { LocalNames, LocalPeopleFull } = searchMethods;
    const page = +routeParams.page || 1;
    const isFirstPage = page === 1;
    const generatedPayload = { ...payloadBase };
    const isPhoneorAddress = routeParams.phone || routeParams.street;
    const firstName = isPhoneorAddress
      ? ''
      : this.getResultNames(routeParams).firstName;
    const lastName = isPhoneorAddress
      ? ''
      : this.getResultNames(routeParams).lastName;
    const firstNameRequestFormat = isPhoneorAddress
      ? ''
      : this.getResultNames(routeParams).firstNameRequestFormat;
    const lastNameRequestFormat = isPhoneorAddress
      ? ''
      : this.getResultNames(routeParams).lastNameRequestFormat;

    const nameParams = {
      lastName: lastNameRequestFormat.replace(/_/g, ' '),
      firstName: firstNameRequestFormat
    };
    const localNameParams = {
      ...nameParams
    };
    const formattedNames = {
      lastName: lastNameRequestFormat,
      firstName: firstNameRequestFormat
    };

    const appendPeopleCount = () => {
      const totalAmount =
        flowType === 'city'
          ? generatedPayload.searchFilterCityList[1].count
          : generatedPayload.searchFilterStateList[1].count;
      // Set total records found count
      generatedPayload.totalPeople = totalAmount;
      // Set plurality for records and matches
      generatedPayload.recordPlural = totalAmount === 1 ? 'record' : 'records';
      generatedPayload.matchPlural = totalAmount === 1 ? 'match' : 'matches';
    };

    /**
     * Override totalPeople if less than 25
     * @param totalPeople
     * @param totalPeopleItems
     * @returns {*}
     */
    const adjustCount = (totalPeople, totalPeopleItems) => {
      if (totalPeopleItems <= PAGE_LIMIT) {
        return totalPeopleItems;
      }
      return totalPeople;
    };

    const prepareMobileFilterDropdowns = (dataSet, options) => {
      if (!Array.isArray(dataSet) || dataSet.length < 1) {
        return [];
      }
      const { filterType, urlBase, stateShort } = options;
      const isState = filterType === 'state';
      const filteredDataSet = isState
        ? dataSet.filter(item => item.state_full)
        : dataSet;

      const builtDataSet = [];
      filteredDataSet.forEach(item => {
        if (item.state === '' || item.city === '') {
          return null;
        }
        const stateParam = item.state || '';
        builtDataSet.push({
          displayName: isState
            ? this.formatName(item.state_full)
            : this.formatName(item.city),
          count: item.count,
          urlValue: isState
            ? `-${stateParam.replace(/ /g, '-').toLowerCase()}/`
            : `${urlBase}/${item.city.replace(/ /g, '-').toLowerCase()}/`
        });
      });

      return builtDataSet;
    };

    const appendSearchFilter = addHash => {
      let stateParam = getStateAbbrFromName({
        state: generatedPayload.searchFilterStateList[1].urlValue.replace(
          '/',
          ''
        )
      });
      const seoStateUrl = getSeoLink({
        router,
        params: {
          firstname: firstName,
          lastname: lastName,
          state: stateParam
        },
        seoType: 'state'
      });
      generatedPayload.searchFilterSelectedState = seoStateUrl;
    };

    switch (flowType) {
      case 'city': {
        const { state, city } = routeParams;
        const stateAbbr = resultHelper.getStateAbbrFromName(
          state.replace(/-|_/g, ' ')
        );
        const stateShort = stateAbbr.toUpperCase();
        const cityName = this.formatName(city.replace(/_|-/g, ' '));
        const cityNameUpper = cityName.toUpperCase().replace(/ /g, '%20');
        const cityQuery = {
          ...localNameParams,
          state: stateShort
        };

        generatedPayload.cityLocationList = await queryHelper(
          cityQuery,
          LocalNames.countByCity,
          []
        );
        generatedPayload.cityLocationList = this.updateSeoResultCounts(
          generatedPayload.cityLocationList
        );
        generatedPayload.totalPeople = await queryHelper(
          localNameParams,
          LocalNames.namesCount,
          0
        );
        generatedPayload.allStateList = await queryHelper(
          localNameParams,
          LocalNames.countByState,
          []
        );
        generatedPayload.allStateList = this.updateSeoResultCounts(
          generatedPayload.allStateList
        );
        generatedPayload.locationList = [];
        generatedPayload.searchFilterCityList = [];
        generatedPayload.location = cityName;

        if (generatedPayload.cityLocationList.length > 0) {
          // filter the city list
          const cityCount = generatedPayload.cityLocationList.filter(
            countItem => countItem.city.toLowerCase() === cityName.toLowerCase()
          )[0];

          const cityCountValue = cityCount ? cityCount.count : 0;
          generatedPayload.locationList.push({
            name: `${cityName}, ${stateShort}`
          });
          generatedPayload.searchFilterCityList = getSelectedLocationArray({
            allText: 'All Cities',
            displayName: cityName,
            count: cityCountValue,
            url: `${state.toLowerCase()}/${cityName.toLowerCase()}/`,
            allUrl: `/${state}/`
          });
        }

        generatedPayload.searchFilterCityLocationPrepend = '';
        generatedPayload.searchFilterStateList = [];

        if (generatedPayload.searchFilterCityList.length > 0) {
          const seoCityUrl = getSeoLink({
            router,
            params: {
              firstname: firstName,
              lastname: lastName,
              state: stateShort,
              city: cityName
            },
            seoType: 'city'
          });
          generatedPayload.searchFilterSelectedCity = seoCityUrl;
          generatedPayload.searchFilterStateList = getSelectedLocationArray({
            allText: 'All States',
            displayName: stateShort,
            count: null, // Do not display number for state on city level
            url: `/${state.toLowerCase()}/`,
            allUrl: ''
          });

          appendSearchFilter();
          appendPeopleCount();
        }

        // Limit API Call to only what is needed on page if we have more than 250 results, otherwise set to 250
        generatedPayload.limit = this.setApiLimit(
          generatedPayload,
          250,
          page,
          'results'
        );
        const filter = this.getFiltersForLocalPeopleFull();

        const cityPeopleQuery = {
          lastName: lastNameRequestFormat,
          firstName: firstNameRequestFormat,
          state: stateShort,
          city: cityNameUpper,
          limit: generatedPayload.limit,
          filter
        };

        // Get Page Results
        generatedPayload.peopleResponse = await queryHelper(
          cityPeopleQuery,
          LocalPeopleFull.byLocation,
          []
        );

        if (isFirstPage) {
          const updatedCityPeopleQuery = {
            ...cityPeopleQuery,
            limit: 250,
            filter: peopleFullResponseFilter
          };
          const peopleFullPhoneAndAddressesData = {
            ...cityPeopleQuery,
            limit: 25,
            skip: 25,
            filter: phoneAddressesFilter
          };
          generatedPayload.peopleFullPhonesAndAddresses = await queryWithSearchSDK(
            {
              queryParams: peopleFullPhoneAndAddressesData,
              searchMethod: LocalPeopleFull.byLocation,
              defaultReturnValue: []
            }
          );
          generatedPayload.peopleFullResponse = await queryWithSearchSDK({
            queryParams: updatedCityPeopleQuery,
            searchMethod: LocalPeopleFull.byLocation,
            defaultReturnValue: []
          });
        }

        if (generatedPayload.peopleResponse.length === 0) {
          // bailout if no results
          generatedPayload.redirect = true;
          return generatedPayload;
        }

        // Total is amount of people possible with cap of 250
        generatedPayload.totalPeopleItems = this.setApiLimit(
          generatedPayload,
          250,
          null,
          'peopleItems'
        );

        generatedPayload.totalPeople = adjustCount(
          generatedPayload.totalPeople,
          generatedPayload.totalPeopleItems
        );

        // Get Letter Page Number
        generatedPayload.indexResponse = await queryHelper(
          formattedNames,
          LocalNames.indexPage,
          ''
        );

        const cityOptions = {
          filterType: 'city',
          urlBase: `${state.toLowerCase()}`,
          stateShort: stateShort
        };
        const stateOptions = {
          filterType: 'state'
        };
        const dataLists = {
          cityLocationList: generatedPayload.cityLocationList,
          locationList: generatedPayload.locationList,
          searchFilterCityList: generatedPayload.searchFilterCityList,
          searchFilterStateList: generatedPayload.searchFilterStateList,
          allCityList: prepareMobileFilterDropdowns(
            generatedPayload.cityLocationList,
            cityOptions
          ),
          allStateList: prepareMobileFilterDropdowns(
            generatedPayload.allStateList,
            stateOptions
          )
        };
        const personNames = {
          firstName,
          lastName,
          firstNameRequestFormat,
          lastNameRequestFormat,
          stateShort,
          cityName
        };

        return this.formatPayload(
          generatedPayload,
          dataLists,
          personNames,
          page,
          route,
          userAgent,
          flowType,
          router
        );
      }
      case 'state': {
        const { state } = routeParams;
        const stateAbbr = resultHelper.getStateAbbrFromName(
          state.replace(/-|_/g, ' ')
        );
        const stateShort = stateAbbr.toUpperCase();
        const cityList = [];
        const cityCount = {};
        const cityQuery = {
          ...localNameParams,
          state: stateShort
        };

        generatedPayload.stateLocationList = await queryHelper(
          nameParams,
          LocalNames.countByState,
          []
        );
        generatedPayload.stateLocationList = this.updateSeoResultCounts(
          generatedPayload.stateLocationList
        );
        generatedPayload.cityLocationList = await queryHelper(
          cityQuery,
          LocalNames.countByCity,
          []
        );
        generatedPayload.cityLocationList = this.updateSeoResultCounts(
          generatedPayload.cityLocationList
        );

        const stateCount = this.getStateCount(
          generatedPayload.stateLocationList,
          stateShort
        );
        const stateCountName =
          stateCount && stateCount.state_full
            ? stateCount.state_full
            : this.getStateFromAbbr(stateCount.state);
        const stateCountValue = stateCount ? stateCount.count : 0;
        generatedPayload.location = stateShort;
        generatedPayload.locationList = generatedPayload.cityLocationList;
        generatedPayload.searchFilterStateList = getSelectedLocationArray({
          allText: 'All States',
          displayName: stateCountName,
          count: stateCountValue,
          url: `/${state}/`,
          allUrl: ''
        });

        if (generatedPayload.locationList.length !== 0) {
          // set preselected state
          appendSearchFilter();
          appendPeopleCount();
        }

        // Limit API Call to only what is needed on page if we have more than 250 results, otherwise set to 250
        generatedPayload.limit = this.setApiLimit(
          generatedPayload,
          250,
          page,
          'results'
        );
        const filter = this.getFiltersForLocalPeopleFull();

        const statePeopleQuery = {
          lastName: lastNameRequestFormat,
          firstName: firstNameRequestFormat,
          state: stateShort,
          limit: generatedPayload.limit,
          filter
        };

        generatedPayload.peopleResponse = await queryHelper(
          statePeopleQuery,
          LocalPeopleFull.byLocation,
          []
        );

        if (isFirstPage) {
          const updatedStatePeopleQuery = {
            ...statePeopleQuery,
            limit: 250,
            filter: peopleFullResponseFilter
          };
          const peopleFullPhoneAndAddressesData = {
            ...statePeopleQuery,
            limit: 25,
            skip: 25,
            filter: phoneAddressesFilter
          };
          generatedPayload.peopleFullPhonesAndAddresses = await queryWithSearchSDK(
            {
              queryParams: peopleFullPhoneAndAddressesData,
              searchMethod: LocalPeopleFull.byLocation,
              defaultReturnValue: []
            }
          );
          generatedPayload.peopleFullResponse = await queryWithSearchSDK({
            queryParams: updatedStatePeopleQuery,
            searchMethod: LocalPeopleFull.byLocation,
            defaultReturnValue: []
          });
        }

        if (generatedPayload.peopleResponse.length === 0) {
          // redirect at the view layer passing flag for quick exit
          generatedPayload.redirect = true;
          return generatedPayload;
        }

        // Total is amount of people possible with cap of 250
        generatedPayload.totalPeopleItems = this.setApiLimit(
          generatedPayload,
          250,
          null,
          'peopleItems'
        );

        generatedPayload.totalPeople = adjustCount(
          generatedPayload.totalPeople,
          generatedPayload.totalPeopleItems
        );

        generatedPayload.indexResponse = await queryHelper(
          formattedNames,
          LocalNames.indexPage,
          ''
        );

        // Format all cities in response and add to total city count
        generatedPayload.peopleResponse.forEach(person => {
          const city = this.formatName(person.city);
          if (!cityList.includes(city)) {
            cityList.push(city);
            cityCount[city] = 1;
          } else {
            cityCount[city] += 1;
          }
        });

        generatedPayload.searchFilterCityList = [];
        // Add cities to dropdowns for mobile
        for (let i = 0; i < generatedPayload.cityLocationList.length; i++) {
          generatedPayload.locationList[i]['name'] = this.formatName(
            generatedPayload.cityLocationList[i].city
          );
          const cityParam = formatCity({
            city: generatedPayload.cityLocationList[i].city
          }).replace(/ /g, '_');
          generatedPayload.searchFilterCityList.push({
            displayName: this.formatName(
              generatedPayload.cityLocationList[i].city
            ),
            urlValue: `/${state}/${cityParam}/`,
            count: generatedPayload.cityLocationList[i].count
          });
        }

        const personNames = {
          firstName,
          lastName,
          firstNameRequestFormat,
          lastNameRequestFormat,
          stateShort
        };
        const stateParam = stateShort;
        const cityOptions = {
          filterType: 'city',
          urlBase: stateParam.replace(/ /g, '-').toLowerCase(),
          stateShort: stateParam.replace(/ /g, '-').toLowerCase()
        };
        const stateOptions = {
          filterType: 'state'
        };

        const dataLists = {
          locationList: generatedPayload.locationList,
          searchFilterCityList: generatedPayload.searchFilterCityList,
          cityLocationList: generatedPayload.cityLocationList,
          searchFilterStateList: generatedPayload.searchFilterStateList,
          allCityList: prepareMobileFilterDropdowns(
            generatedPayload.cityLocationList,
            cityOptions
          ),
          allStateList: prepareMobileFilterDropdowns(
            generatedPayload.stateLocationList,
            stateOptions
          )
        };

        return this.formatPayload(
          generatedPayload,
          dataLists,
          personNames,
          page,
          route,
          userAgent,
          flowType,
          router
        );
      }
      case 'root': {
        generatedPayload.locationList = await queryHelper(
          localNameParams,
          LocalNames.countByState,
          []
        );
        generatedPayload.locationList = this.updateSeoResultCounts(
          generatedPayload.locationList
        );
        generatedPayload.totalPeople = await queryHelper(
          localNameParams,
          LocalNames.namesCount,
          0
        );
        generatedPayload.totalPeople = this.updateCount(
          generatedPayload.totalPeople
        );

        const pluralPeople = generatedPayload.totalPeople === 1;
        generatedPayload.recordPlural = pluralPeople ? 'record' : 'records';

        // Limit API Call to only what is needed on page if we have more than 250 results, otherwise set to 250
        generatedPayload.limit = this.setApiLimit(
          generatedPayload,
          250,
          page,
          'results'
        );

        const filter = this.getFiltersForLocalPeopleFull();

        const peopleFullData = {
          firstName,
          lastName: lastName.replace('_', ' '),
          limit: generatedPayload.limit,
          filter
        };

        generatedPayload.peopleResponse = await queryHelper(
          peopleFullData,
          LocalPeopleFull.byName,
          []
        );

        if (isFirstPage) {
          const updatedPeopleFullData = {
            ...peopleFullData,
            limit: 250,
            filter: peopleFullResponseFilter
          };
          const peopleFullPhoneAndAddressesData = {
            ...peopleFullData,
            limit: 25,
            skip: 25,
            filter: phoneAddressesFilter
          };
          generatedPayload.peopleFullPhonesAndAddresses = await queryWithSearchSDK(
            {
              queryParams: peopleFullPhoneAndAddressesData,
              searchMethod: LocalPeopleFull.byName,
              defaultReturnValue: []
            }
          );
          generatedPayload.peopleFullResponse = await queryWithSearchSDK({
            queryParams: updatedPeopleFullData,
            searchMethod: LocalPeopleFull.byName,
            defaultReturnValue: []
          });
        }

        // Total is amount of people possible with cap of 250
        generatedPayload.totalPeopleItems = this.setApiLimit(
          generatedPayload,
          250,
          null,
          'peopleItems'
        );

        generatedPayload.totalPeople = adjustCount(
          generatedPayload.totalPeople,
          generatedPayload.totalPeopleItems
        );

        generatedPayload.indexResponse = await queryHelper(
          formattedNames,
          LocalNames.indexPage,
          ''
        );

        const { locationList, searchFilterStateList } = setLocationList({
          peopleResponse: generatedPayload.peopleResponse,
          locationList: generatedPayload.locationList
        });
        const dataLists = {
          locationList,
          searchFilterStateList,
          allStateList: searchFilterStateList
        };
        const personNames = {
          firstName,
          lastName,
          firstNameRequestFormat,
          lastNameRequestFormat
        };
        return this.formatPayload(
          generatedPayload,
          dataLists,
          personNames,
          page,
          route,
          userAgent,
          flowType,
          router
        );
      }
      case 'phone': {
        const { phone } = routeParams;
        // TODO: Apply filter once endpoint has been updated to accepted it
        // const filter = this.getFiltersForLocalPeopleFull();
        const phoneQuery = {
          phone: phone.replace(/-/g, ''),
          limit: 250
        };

        try {
          generatedPayload.peopleResponse = await queryHelper(
            phoneQuery,
            LocalPeopleFull.byPhone,
            []
          );
          if (
            generatedPayload.peopleResponse.type &&
            generatedPayload.peopleResponse.type.includes('error')
          ) {
            generatedPayload.redirect = true;
            return generatedPayload;
          }
        } catch (error) {
          $bugsnag?.notify(error);
          console.error(error);
          generatedPayload.peopleResponse = [];
        }

        if (generatedPayload.peopleResponse.length === 0) {
          // redirect at the view layer passing flag for quick exit
          generatedPayload.redirect = true;
          return generatedPayload;
        }

        // Total is amount of people possible with cap of 250
        generatedPayload.totalPeopleItems = this.setApiLimit(
          generatedPayload,
          250,
          null,
          'peopleItems'
        );
        generatedPayload.totalPeople = getOffsetCount({
          count: generatedPayload.peopleResponse.length
        });
        generatedPayload.limit =
          generatedPayload.totalPeople >= 250 ? PAGE_LIMIT * page : 250;

        generatedPayload.phone = phone
          ? formatPhoneNumber({ phoneNumber: phone })
          : '';

        return this.formatPayload(
          generatedPayload,
          null,
          null,
          page,
          route,
          userAgent,
          flowType,
          router
        );
      }
      case 'address': {
        const filter = this.getFiltersForLocalPeopleFull();
        const { city, state } = routeParams;
        // To avoid getting a 404, replace # with |
        const street = setTitleCase({
          text: routeParams.street.replace(/-/g, ' ').replace('|', '#')
        });
        let streetNum;
        let streetName;
        let streetType;
        const streetArr = routeParams.street.split('-');
        streetNum = streetArr[0];
        if (
          streetNum.toUpperCase().includes('PO') ||
          streetNum.toUpperCase().includes('P/O')
        ) {
          streetNum = streetArr[streetArr.length - 1];
          streetArr.splice(streetArr.length - 1, 1);
        } else {
          // Check if streetNum doesn't contain a number
          if (/^\D*$/.test(streetNum)) {
            // We'll just set streetNum to empty string since it's missing from url
            streetArr.unshift('');
            streetNum = streetArr[0];
          }
          streetArr.splice(0, 1);
          const streetArrayCopy = streetArr;
          const streetTypeArray = streetArrayCopy.splice(
            streetArr.length - 1,
            streetArr.length
          );
          streetType = streetTypeArray[0];
        }
        streetName = streetArr.join(' ').trim();
        const cityParam = formatCity({ city });
        const stateParam = state.toUpperCase();
        const addressQuery = {
          street: encodeURIComponent(street),
          city: cityParam,
          state: stateParam,
          filter,
          limit: 250
        };
        try {
          // Make sure there's a streetNumber before doing initial query otherwise return empty array
          if (streetNum) {
            generatedPayload.peopleResponse = await queryHelper(
              addressQuery,
              LocalPeopleFull.byStreet,
              []
            );
          } else {
            generatedPayload.peopleResponse = [];
          }
          // If no results then query again with no street number and shuffle results
          if (!generatedPayload.peopleResponse.length) {
            const shuffle = array => {
              for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
              }
              return array;
            };
            generatedPayload.peopleResponse = await queryHelper(
              {
                streetname: streetName,
                streettype: streetType,
                city: cityParam,
                state: stateParam,
                filter,
                limit: 250
              },
              LocalPeopleFull.byStreet,
              []
            );
            generatedPayload.peopleResponse = shuffle(
              generatedPayload.peopleResponse
            );
          }

          // If still no results set redirect
          if (
            generatedPayload.peopleResponse.type &&
            generatedPayload.peopleResponse.type.includes('error')
          ) {
            generatedPayload.redirect = true;
          }
        } catch (error) {
          $bugsnag?.notify(error);
          console.error(error);
        }

        if (generatedPayload.peopleResponse.length === 0) {
          // redirect at the view layer passing flag for quick exit
          generatedPayload.redirect = true;
          return generatedPayload;
        }

        // Total is amount of people possible with cap of 250
        generatedPayload.totalPeopleItems = this.setApiLimit(
          generatedPayload,
          250,
          null,
          'peopleItems'
        );
        generatedPayload.totalPeople = getOffsetCount({
          count: generatedPayload.peopleResponse.length
        });
        generatedPayload.limit =
          generatedPayload.totalPeople >= 250 ? PAGE_LIMIT * page : 250;

        generatedPayload.currentResident = generatedPayload.peopleResponse[0]
          ? getCurrentResident(
              generatedPayload.peopleResponse[0],
              state,
              city.toUpperCase(),
              streetName.toUpperCase(),
              streetNum
            )
          : null;

        generatedPayload.mapboxToken = process.env.MAP_BOX_TOKEN;

        return this.formatPayload(
          generatedPayload,
          null,
          null,
          page,
          route,
          userAgent,
          flowType,
          router
        );
      }
      default:
        return {};
    }
  },
  /**
   * formatPayload - Shapes and formats api data into what the view layer is looking for
   * @param {object} payload - raw query data in an object
   * @param {object} lists - an object of arrays that contains iterable lists for the view
   * @param {object} names - an object containing the searched persons name in various formats
   * @param {number} page - the page number for the results
   * @param {object} route - route object from vue/nuxt
   * @param {string} userAgent - the browser/device that made the request for information
   * @param {string} flowType - what flow we are in eg. root, city, state
   * @param {object} router
   * @returns {object} a payload that is formatted for use in various views
   */
  formatPayload: function(
    payload,
    lists,
    names,
    page,
    route,
    userAgent,
    flowType,
    router
  ) {
    const formattedPayload = { ...payload };
    const firstName = names?.firstName || '';
    const lastName = names?.lastName || '';
    const firstNameRequestFormat = names?.firstNameRequestFormat || '';
    const lastNameRequestFormat = names?.lastNameRequestFormat || '';
    const stateShort = names?.stateShort || '';
    const cityName = names?.cityName || '';
    const locationList = lists?.locationList || '';
    const searchFilterStateList = lists?.searchFilterStateList || '';
    const searchFilterCityList = lists?.searchFilterCityList || '';
    const cityLocationList = lists?.cityLocationList || '';
    const allCityList = lists?.allCityList || '';
    const allStateList = lists?.allStateList || '';

    formattedPayload.locationList = (locationList || []).map(
      resultHelper.getOffsetCountMap
    );
    formattedPayload.searchFilterStateList = (searchFilterStateList || []).map(
      resultHelper.getOffsetCountMap
    );
    formattedPayload.searchFilterCityList = (searchFilterCityList || []).map(
      resultHelper.getOffsetCountMap
    );
    formattedPayload.allCityList = (allCityList || []).map(
      resultHelper.getOffsetCountMap
    );
    formattedPayload.allStateList = (allStateList || []).map(
      resultHelper.getOffsetCountMap
    );
    formattedPayload.cityLocationList = (cityLocationList || []).map(
      resultHelper.getOffsetCountMap
    );

    formattedPayload.peoplePages = helpers.chunk(
      formattedPayload.peopleResponse,
      formattedPayload.itemsPerPage
    );

    formattedPayload.totalPages = formattedPayload.peoplePages.length;
    formattedPayload.currentPage =
      page <= formattedPayload.totalPages ? page : 1;
    formattedPayload.currentPageIndex = formattedPayload.currentPage - 1;
    formattedPayload.people =
      formattedPayload.peoplePages[formattedPayload.currentPageIndex] || [];

    formattedPayload.people = this.setPeopleAges(formattedPayload.people);

    if (flowType === 'root' || flowType === 'address') {
      formattedPayload.people = this.unlinkRelatives(
        formattedPayload.people,
        firstNameRequestFormat,
        lastNameRequestFormat
      );
    }

    const crimesData = getCrimeData({ payload: formattedPayload });

    formattedPayload.crimes = crimesData.crimes;
    formattedPayload.criminalRecords = crimesData.limitedCrimes;
    formattedPayload.criminalRecordsSexOffenderCount =
      crimesData.criminalRecordsSexOffenderCount;
    formattedPayload.qnaTitle = setNbarsFullName({
      firstName: firstName.replace(/\+/, ' '),
      lastName: lastName.replace(/\+/, ' '),
      content: pageCms.qna.title
    });
    formattedPayload.qnaTokens = this.setQnaTokens(
      formattedPayload.people,
      firstName.replace(/\+/, ' '),
      lastName.replace(/\+/, ' '),
      router
    );
    formattedPayload.qnaItems = this.setQnaItems(formattedPayload.qnaTokens);
    formattedPayload.isMobile = this.isMobile(userAgent);
    formattedPayload.socialMedia = this.setSocialMediaData(
      formattedPayload.people,
      firstName,
      lastName,
      10
    );
    formattedPayload.phoneNumbers = this.setPhoneNumbers(
      formattedPayload.people
    );
    formattedPayload.emailAddresses = this.setEmailAddresses(
      formattedPayload.people,
      6,
      true
    );
    formattedPayload.employers = this.setEmploymentHistory(
      formattedPayload.people
    );
    formattedPayload.professions = selectPeopleByOccupation({
      peopleData: formattedPayload.peopleFullResponse,
      positions: [
        'PROFESSOR',
        'TEACHER',
        'ATTORNEY',
        'LAWYER',
        'REALTOR',
        'BROKER'
      ]
    });
    formattedPayload.landingUrl = this.setLandingUrl(
      route.query,
      firstName,
      lastName,
      stateShort,
      cityName
    );
    formattedPayload.relativeList = this.getRelativesFormattedForSection(
      formattedPayload.peopleResponse
    );
    formattedPayload.peopleDataSums = this.getAllPagePeopleDataSums(
      formattedPayload.people
    );
    formattedPayload.randomEmailAddresses = this.formatRandomEmails(
      formattedPayload,
      router
    );
    formattedPayload.randomPhoneNumbers = this.formatRandomPhoneNumbers(
      formattedPayload,
      router
    );

    formattedPayload.tocList = this.getTocList(
      formattedPayload.people,
      pageCms.toc_links
    ).filter(toc => toc.count !== 0 && !toc.hide);
    // Remove the profession from list if there are no professions
    if (!formattedPayload.professions.length) {
      formattedPayload.tocList = formattedPayload.tocList.filter(
        toc => toc.label !== 'Profession'
      );
    }
    // Remove from list if there are no phone and addresses data
    if (
      !formattedPayload.peopleFullPhonesAndAddresses ||
      !formattedPayload.peopleFullPhonesAndAddresses.length
    ) {
      formattedPayload.tocList = formattedPayload.tocList.filter(
        toc => toc.label !== 'Addresses'
      );
    }

    return formattedPayload;
  },
  /**
   * Return the QNA tokens required for display
   *
   * @param {array} people
   * @param {string} firstName
   * @param {string} lastName
   * @param {object} router
   * @returns {{emails: (string|null), firstName: string, lastName: string, aliases: (string|null), address: (string|null), phone: (string|null), fullName: string, alias: (string|null), oldestAge: *, age: *, email: (string|null), youngestAge: *}}
   */
  setQnaTokens: function(people, firstName, lastName, router) {
    const agesItems = [...people]
      .filter(person => {
        const cutoffDate = new Date('1900-01-01');
        const date = new Date(person.dob);
        if (date > cutoffDate) return date;
      })
      .sort((a, b) => (a.dob < b.dob ? 1 : -1))
      .map(
        person =>
          new Date(Date.now() - new Date(person.dob)).getFullYear() - 1970
      );
    const dobItems = [...people]
      .filter(person => {
        const cutoffDate = new Date('1900-01-01');
        const date = new Date(person.dob);
        if (date > cutoffDate) return date;
      })
      .sort((a, b) => (a.dob > b.dob ? 1 : -1))
      .map(person => new Date(person.dob).getFullYear());

    const personAddresses = [...people].filter(
      person => Array.isArray(person.addresses) && person.addresses.length > 0
    )[0]?.addresses;

    const addressItems = personAddresses ? [personAddresses[0]] : null;
    const previousCities =
      personAddresses && personAddresses.length > 1
        ? this.getPreviousCities([...personAddresses])
        : null;

    const phoneItems = [...people]
      .filter(person => (person.phones ? person.phones.length : null))
      .map(person => [person.phones[0]])[0];
    const emailItemsSingular = [...people]
      .filter(person => (person.emails ? person.emails.length === 1 : null))
      .map(person => {
        return person.emails;
      })[0];
    const emailItemsMultiple = [...people]
      .filter(person => (person.emails ? person.emails.length > 1 : null))
      .map(person => {
        return person.emails;
      })[0];
    const aliasItemsSingular = [...people]
      .filter(person => (person.aliases ? person.aliases.length === 1 : null))
      .map(person => {
        return person.aliases;
      })[0];
    const aliasItemsMultiple = [...people]
      .filter(person => (person.aliases ? person.aliases.length > 1 : null))
      .map(person => {
        return person.aliases;
      })[0];
    const selectedRelatives = [...people]
      .filter(
        person => Array.isArray(person.relatives) && person.relatives.length > 0
      )
      .map(person => {
        return person.relatives;
      });
    const relativesItemsMultiple = selectedRelatives.length
      ? selectedRelatives[0].filter(
          relative => relative.city !== '' && relative.state !== ''
        )
      : [];
    const income = this.rand(45, 110);

    const tokens = {
      firstName,
      lastName,
      fullName: `${firstName} ${lastName}`,
      year: dobItems[0],
      oldestDob: dobItems[0],
      youngestDob: dobItems[dobItems.length - 1],
      ages: agesItems,
      age: agesItems[0] > 80 ? '80+' : agesItems[0],
      youngestAge: agesItems[0],
      oldestAge:
        agesItems[agesItems.length - 1] > 80
          ? '80+'
          : agesItems[agesItems.length - 1],
      address: this.formatAddressHistoryAsString(
        addressItems,
        1,
        false,
        1,
        0,
        true
      ),
      previousCities,
      phone: this.formatPhoneNumbersAsString(phoneItems, 1, false, 10, true),
      email: this.formatEmailListAsString(
        emailItemsSingular,
        1,
        true,
        false,
        true
      ),
      alias: this.formatAliasList(aliasItemsSingular, 1, true),
      emails: this.formatEmailListAsString(
        emailItemsMultiple,
        3,
        true,
        true,
        true
      ),
      aliases: this.formatAliasList(aliasItemsMultiple, 3, true),
      relatives: this.formatRelativesList(relativesItemsMultiple, 3, router),
      relationships: this.rand(45, 60),
      incomeLow: income - 5,
      incomeHigh: income + 5
    };

    return tokens;
  },
  /**
   * Return a string of non-duplicate previous cities
   * @param {array} addresses
   * @returns {null|string}
   */
  getPreviousCities: function(addresses) {
    const currentCity = helpers.setTitleCase(addresses[0].city);
    const previousCitiesArr = [currentCity];
    addresses.forEach(address => {
      const city = helpers.setTitleCase(address.city);
      if (!previousCitiesArr.includes(city) && previousCitiesArr.length <= 4) {
        previousCitiesArr.push(city);
      }
    });
    //remove current city
    previousCitiesArr.splice(0, 1);
    if (!previousCitiesArr.length) {
      return null;
    } else if (previousCitiesArr.length > 2) {
      previousCitiesArr[previousCitiesArr.length - 1] =
        'and ' + previousCitiesArr[previousCitiesArr.length - 1];
      return previousCitiesArr.join(', ') + '.';
    }
    return previousCitiesArr.join(' and ') + '.';
  },
  /**
   * Return the total amount to display - total possible or length of array, whichever is smaller
   *
   * @param array
   * @param totalPossible
   * @returns {int}
   */
  calculateDisplayMax: function(array, totalPossible) {
    return array.length < totalPossible ? array.length : totalPossible;
  },
  /**
   * Return an array of relatives as a formatted string for display
   * and linked profiles
   * @param {array} relatives
   * @param {number} displayCount
   * @param {object} router
   * @returns {null|string}
   */
  formatRelativesList: function(relatives, displayCount, router) {
    // Early exit if no relatives
    if (!relatives) {
      return null;
    }

    // Get total possible for display
    const totalPossible = this.calculateDisplayMax(relatives, displayCount);

    let formatted = '';
    const items = this.getRandomItems(relatives, totalPossible);

    // Go through each relative and format for page
    for (let i = 0; i < totalPossible; i++) {
      const isLast = i === totalPossible - 1;
      const isSecondToLast = i === totalPossible - 2;
      const { firstname, lastname } = items[i];
      const firstName = this.setTitleCase(firstname);
      const lastName = this.setTitleCase(lastname);
      const firstNameParam = firstName
        .replace(spaceRegEx, '_')
        .replace(dashRegEx, '');
      const lastNameParam = lastName.replace(/ |-/g, '_');
      const relativeLink = getSeoLink({
        router,
        params: {
          firstname: firstNameParam,
          lastname: lastNameParam
        },
        seoType: 'root'
      });

      if (totalPossible === 1) {
        formatted += `[${helpers.formatName(
          `${firstname} ${lastname}`
        )}](${relativeLink})`;
      } else if (isSecondToLast) {
        formatted += `${this.lastWord(isLast, ' and ')}[${helpers.formatName(
          `${firstname} ${lastname}`
        )}](${relativeLink})`;
      } else if (totalPossible === 2) {
        formatted += `${this.lastWord(isLast, ' and ')}[${helpers.formatName(
          `${firstname} ${lastname}`
        )}](${relativeLink})`;
      } else {
        formatted += `${this.lastWord(isLast, ' and ')}[${helpers.formatName(
          `${firstname} ${lastname}`
        )}](${relativeLink})${this.getSeparatorSymbol(isLast)}`;
      }
    }

    return formatted === '' ? null : formatted;
  },
  /**
   * Gets link from relative data object for relative's profile page, or if no ID, link to step one
   * @param {object} relative
   * @returns {string}
   */
  getLinkToProfileOrStepOne(relative) {
    if (!relative.id) {
      return this.getLinkToCPStepOne(relative, landingId);
    }
    return this.getProfileLink(relative);
  },
  /**
   * Data for the questions and answers component
   * @param qnaTokens
   * @returns {({questions: *, answers: (*|{no_value}[])}|{questions: *, answers: (*|{no_value}[])}|{questions: *, answers: (*|{no_value}[])}|{questions: *, answers: (*|{no_value}[])}|{questions: *, answers: (*|{no_value}[])})[]}
   */
  setQnaItems(qnaTokens) {
    function singularValueMap({ singular_value }) {
      return { singular_value };
    }

    function multipleValueMap({ multiple_values }) {
      return { multiple_values };
    }

    function noValueMap({ no_value }) {
      return { no_value };
    }

    let relativesAnswers = answerRelativesItems.map(noValueMap);

    if (qnaTokens.relatives) {
      relativesAnswers = answerRelativesItems.map(multipleValueMap);
    }

    let phoneAnswers = answerPhoneItems.map(noValueMap);

    if (qnaTokens.phone) {
      phoneAnswers = answerPhoneItems.map(singularValueMap);
    }

    let relationshipsAnswers = answerRelationshipsItems.map(noValueMap);

    if (qnaTokens.relationships) {
      relationshipsAnswers = answerRelationshipsItems.map(multipleValueMap);
    }

    let moneyAnswers = answerMoneyItems.map(noValueMap);

    if (qnaTokens.incomeHigh && qnaTokens.incomeLow) {
      moneyAnswers = answerMoneyItems.map(multipleValueMap);
    }

    let emailAnswers = answerEmailItems.map(noValueMap);

    if (qnaTokens.emails) {
      emailAnswers = answerEmailItems.map(multipleValueMap);
    } else if (qnaTokens.email) {
      emailAnswers = answerEmailItems.map(singularValueMap);
    }

    let ageAnswers = answerAgeItems.map(noValueMap);

    if (qnaTokens.ages.length > 1) {
      ageAnswers = answerAgeItems.map(multipleValueMap);
    } else if (qnaTokens.ages.length === 1) {
      ageAnswers = answerAgeItems.map(singularValueMap);
    }

    // age questions need specific answer
    let ageQuestions = questionAgeItems;
    const randomAgeQuestion =
      ageQuestions[Math.floor(Math.random() * ageQuestions.length)];
    const randomAgeQuestionIndex = ageQuestions.indexOf(randomAgeQuestion);
    const ageAnswer = ageAnswers[randomAgeQuestionIndex];
    ageQuestions = [randomAgeQuestion];
    ageAnswers = [ageAnswer];

    let addressAnswers = [];
    const hasAddress = qnaTokens.address && qnaTokens.address !== '';
    if (hasAddress && !qnaTokens.previousCities) {
      addressAnswers = answerAddrItems;
    } else if (hasAddress && qnaTokens.previousCities) {
      answerAddrItems.forEach(answerObj => {
        addressAnswers.push({
          singular_value:
            answerObj.singular_value +
            ` Previous cities include ${qnaTokens.previousCities}`,
          no_value: answerObj.no_value
        });
      });
    } else {
      addressAnswers = [
        {
          no_value: answerAddrItems[0].no_value
        }
      ];
    }

    const items = [
      {
        questions: questionPhoneItems,
        answers: phoneAnswers
      },
      {
        questions: questionAddrItems,
        answers: addressAnswers
      },
      {
        questions: questionEmailItems,
        answers: emailAnswers
      },
      {
        questions: ageQuestions,
        answers: ageAnswers
      },
      {
        questions: questionRelativesItems,
        answers: relativesAnswers
      },
      {
        questions: questionRelationshipsItems,
        answers: relationshipsAnswers
      },
      {
        questions: questionMoneyItems,
        answers: moneyAnswers
      }
    ];

    return items;
  },
  getLinkToCPStepOne(person, landingId, isLanding = false) {
    const cidEncoded = isLanding ? person.cid : this.encodeCID(person.cid);
    const searchingParams = new URLSearchParams({
      firstName: this.setTitleCase(person.firstname),
      lastName: this.setTitleCase(person.lastname),
      state: person.state,
      city: this.setTitleCase(person.city),
      providerID: cidEncoded,
      aid: '26',
      sid: 'INFORMATION',
      tid: '3',
      sub1: '3',
      sub2: 'INFORMATION'
    });
    return `https://www.chkppl.com/cmp/M4MBH/GNS64/?${searchingParams}`;
  },
  getEverFlowLink(person) {
    const cid = person.cid.includes('-')
      ? this.encodeCID(person.cid)
      : person.cid;
    const firstNameParam = helpers.formatName(person.firstname);
    const lastNameParam = helpers.formatName(person.lastname);
    const stateParam = person.state;
    const cityParam = person.city
      ? helpers.formatName(person.city).replace(resultHelper.spaceRegEx, '_')
      : '';

    return `https://www.chkppl.com/cmp/M4MBH/GNS64/?sub1=3&sub2=INFORMATION&sid=INFORMATION&providerID=${cid}&firstName=${firstNameParam}&lastName=${lastNameParam}&state=${stateParam}&city=${cityParam}`;
  },
  /**
   * formatRandomEmails - builds email object for contactInformation section
   * @param {object} payload - the existing payload generated by api queries
   * @param {string} firstName - the first name of the search
   * @param {string} lastName - the last name of the search
   * @returns {*[]} - an array of objects
   */
  formatRandomEmails: function(payload, router) {
    if (
      !payload.hasOwnProperty('emailAddresses') ||
      !Array.isArray(payload.emailAddresses)
    ) {
      return [];
    }

    const emailObjects = [];
    const emails = payload.emailAddresses;

    emails.forEach(emailObj => {
      const obj = {
        title: emailObj.email.toLowerCase(),
        href: formatEverFlowLink({
          person: emailObj.person,
          query: router?.query || {}
        })
      };

      emailObjects.push(obj);
    });
    return emailObjects;
  },
  /**
   * formatRandomPhoneNumbers - generates a list of random phone numbers
   * @param {object} payload - the existing payload generated by api queries
   * @param {string} firstName
   * @param {string} lastName
   * @returns {*[]}
   */
  formatRandomPhoneNumbers: function(payload, router) {
    if (
      !payload.hasOwnProperty('people') ||
      !Array.isArray(payload.people) ||
      payload.people.length === 0
    ) {
      return [];
    }

    const phoneNumbers = getRandomPhoneNumbers({ people: payload.people });

    const phoneObjects = [];

    if (!phoneNumbers.length) {
      return [];
    }

    const getPerson = phone => {
      phone = phone.replace(/[()\-\s]/g, '').trim();
      for (let i = 0; i < payload.people.length; i++) {
        if (
          payload.people[i]?.phones?.length &&
          payload.people[i].phones[0].full == phone
        ) {
          return payload.people[i];
        }
      }
      return '';
    };

    phoneNumbers.split(',').forEach(phoneNumber => {
      if (!phoneNumber) {
        return;
      }

      const person = getPerson(phoneNumber);

      const obj = {
        title: phoneNumber,
        href: formatEverFlowLink({
          person,
          query: router?.query || {}
        })
      };
      phoneObjects.push(obj);
    });

    return phoneObjects;
  },
  /**
   * Get Profile Link
   * @param person
   * @param router
   * @returns {*}
   */
  getProfileLink(person, router) {
    if (router && person.id) {
      const { href } = router.resolve({
        name: 'seo.profile',
        params: {
          firstName: person.firstname,
          lastName: person.lastname,
          city: person.city,
          state: person.state.toLowerCase(),
          uuid: person.id
        }
      });
      return href;
    } else if (router && !person.id) {
      return this.setLandingUrl(
        '',
        person.firstname,
        person.lastname,
        person.state,
        person.city
      );
    }

    // Create Profile URL Structure
    const firstNameParam = person.firstname.replace(/ /g, '_');
    const lastNameParam = person.lastname.replace(/ /g, '_');
    const letterParam = lastNameParam.charAt(0);
    const root = `/${letterParam}/${firstNameParam}-${lastNameParam}`;
    const stateParam = person.state;
    const cityParam = `${person.city.replace(this.spaceRegEx, '_')}`;
    const profile = person.id;
    const profileLink = `${root}-${stateParam}-${cityParam}/${profile}`.toLowerCase();

    return profileLink;
  },
  /**
   * Return a string with requested number of masking symbols
   *
   * @param {number} number
   * @returns {string}
   */
  getMaskingString: function(number) {
    let finalString = '';

    for (let i = 0; i < number; i++) {
      finalString += symbols.masking;
    }

    return finalString;
  },
  /**
   * Return array of relatives as array formatted as [{name: string, url: string}]
   *
   * @param {array} relativesList
   * @param {number} displayCount
   * @param {boolean} toProfile
   * @returns {array|null}
   */
  formatRelativesArray: function(relativesList, displayCount) {
    // Early exit if there are no relatives
    if (!relativesList || relativesList.length === 0) {
      return null;
    }

    // Calculate total possible for display
    const totalPossible = resultHelper.calculateDisplayMax(
      relativesList,
      displayCount
    );

    const formattedRelatives = [];

    // Go through each relative we can display
    for (let i = 0; i < totalPossible; i++) {
      const firstName = this.setTitleCase(relativesList[i].firstname);
      const lastName = this.setTitleCase(relativesList[i].lastname);
      const city = relativesList[i].city?.replace(/ /g, '_');
      const state = relativesList[i].state;
      const firstNameParam = firstName
        .replace(spaceRegEx, '_')
        .replace(dashRegEx, '');
      const lastNameParam = lastName.replace(/ |-/g, '_');
      const letterParam = lastNameParam.charAt(0);
      // Create object and push to array
      const relative = {
        name: `${firstName} ${lastName}`
      };

      // If a relative has an id, link them to their profile page. Else link them to the seo root page
      if (state && city && relativesList[i].id) {
        relative[
          'url'
        ] = `/${letterParam}/${firstNameParam}-${lastNameParam}-${state}-${city}/${relativesList[i].id}/`.toLowerCase();
      } else {
        relative['url'] = `/${letterParam}/${firstNameParam}-${lastNameParam}/`;
      }

      formattedRelatives.push(relative);
    }

    return formattedRelatives;
  },
  /**
   * Return address as a title cased string with optional masking
   *
   * @param address
   * @param separator
   * @param shouldMaskStreet
   * @param {boolean|null} spaceBeforeSeparator
   * @returns {string}
   */
  formatAddress: function(
    address,
    separator,
    shouldMaskStreet,
    spaceBeforeSeparator = false
  ) {
    // Map over street address object to ensure title case
    let street = address.street_name
      ? [
          address.street_number,
          address.street_name,
          address.street_type,
          address.street_affix
        ]
          .map(function(item) {
            // changes street number to a string
            if (typeof item === 'number') {
              return item.toString();
            }
            return helpers.setTitleCase(item);
          })
          .filter(function(item) {
            return item !== undefined;
          })
      : [];

    // If we should mask the street name
    if (shouldMaskStreet && street !== []) {
      // Street name is the first and last item of street array with masking string in between
      street = `${street[0]} ${this.getMaskingString(5)} ${
        street[street.length - 1]
      }`;
      // Otherwise street name is recompiled with title case
    } else {
      street = street.join(' ');
    }

    // Return the newly formatted street name and remaining address data
    return `${street ? street + ', ' : ''}${this.formatAddressWithoutStreet(
      address,
      separator,
      spaceBeforeSeparator
    )}`;
  },
  /**
   * Return formatted city, state, and zipcode
   *
   * @param address
   * @param separator
   * @param {boolean|null} spaceBeforeSeparator
   * @returns {string}
   */
  formatAddressWithoutStreet: function(
    address,
    separator,
    spaceBeforeSeparator = false
  ) {
    // Split the city name on any spacing
    let city =
      typeof address === 'object' && address.city
        ? address.city.split(' ')
        : null;

    if (city) {
      // Ensure every part of the city name is title case
      for (let i = 0; i < city.length; i++) {
        city[i] = helpers.setTitleCase(city[i]);
      }

      city = city.join(' ');
    }

    // Return the title cased city name, state, and zip code
    return `${city ? city + ',' : ''} ${address.state} ${address.zip}${
      spaceBeforeSeparator ? ' ' : ''
    }${separator}`;
  },
  /**
   * Convert an array of addresses into formatted strings for display
   *
   * @param {array} addressList
   * @param {number} displayCount
   * @param {boolean} shouldMaskStreet
   * @param {number} streetCount
   * @param {boolean} isRandom
   * @param {number} startAt
   * @param {boolean|null} spaceBeforeSeparator
   * @returns {string|null}
   */
  formatAddressHistoryAsString: function(
    addressList,
    displayCount,
    shouldMaskStreet,
    streetCount,
    startAt = 0,
    isRandom = false,
    spaceBeforeSeparator
  ) {
    // Early exit if no addresses
    if (!addressList) {
      return null;
    }

    // Get the amount to display on page
    const totalPossible = resultHelper.calculateDisplayMax(
      addressList,
      displayCount
    );

    let formattedAddresses = '';
    let items = addressList;
    if (isRandom) {
      items = this.getRandomItems(addressList, totalPossible);
    }

    // Go through each address we can display
    for (let i = startAt; i < totalPossible; i++) {
      const isLast = i === totalPossible - 1;
      // Format address with street name only if it is available
      if (i < streetCount) {
        formattedAddresses += this.formatAddress(
          items[i],
          this.getSeparatorSymbol(isLast, true),
          shouldMaskStreet,
          spaceBeforeSeparator
        );
        // Otherwise format address with only city, state, and zip
      } else {
        const separatorSymbol = this.getSeparatorSymbol(isLast, true);
        const shortenedAddress = this.formatAddressWithoutStreet(
          addressList[i],
          separatorSymbol,
          spaceBeforeSeparator
        );
        const shortAddressWithNoSeparator = shortenedAddress.replace(
          separatorSymbol,
          ''
        );
        if (!formattedAddresses.includes(shortAddressWithNoSeparator)) {
          formattedAddresses += shortenedAddress;
        }
      }
    }
    if (formattedAddresses.endsWith(this.getSeparatorSymbol(false, true))) {
      const addressesWithoutEndSeparator = formattedAddresses.substring(
        0,
        formattedAddresses.length - 2
      );
      formattedAddresses = addressesWithoutEndSeparator;
    }
    return formattedAddresses;
  },
  /**
   *  Takes people response and sorts the people by their current residence, it will return an array sorted with the greatest matches of state, city, streetname, and streetnum first
   *
   * @param {array} peopleResponse
   * @param {string} state
   * @param {string} city
   * @param {string} streetName
   * @param {string} streetNum
   * @returns {array}
   */
  sortPeopleByCurrentResidence(
    peopleResponse,
    state,
    city,
    streetName,
    streetNum
  ) {
    return peopleResponse.sort((p1, p2) => {
      if (p1.state !== state && p2.state === state) {
        return -1;
      }
      if (p1.city !== city && p2.city === city) {
        return -1;
      }
      if (
        !streetName.indexOf(p1.street_name) &&
        streetName.indexOf(p2.street_name)
      ) {
        return -1;
      }
      if (p1.street_number !== streetNum && p2.street_number === streetNum) {
        return -1;
      }
      return 0;
    });
  },
  /**
   * checks to see if resident is the current resident, if not returns null
   *
   * @param {array} resident
   * @param {string} state
   * @param {string} city
   * @param {string} streetName
   * @param {string} streetNum
   * @returns {array|null}
   */
  getCurrentResident(resident, state, city, streetName, streetNum) {
    if (
      resident.state === state &&
      resident.city === city &&
      streetName.includes(resident.street_name) &&
      resident.street_number == streetNum
    ) {
      return resident;
    }
    return null;
  },
  /**
   * Format person list aliases
   * @param person
   * @returns {{itempropType: string, label: string, list: *[], separator: string}|*[]}
   */
  formatPersonListAliases(person) {
    if (!(Array.isArray(person.aliases) && person.aliases.length)) {
      return null;
    }

    const aliases = [];

    for (
      let i = 0;
      i < person.aliases.slice(0, pageCms.person_card.count_alias).length;
      i++
    ) {
      aliases.push(this.setTitleCase(person.aliases[i]));
    }

    return {
      label: 'Aliases (AKA): ',
      className: 'person__info--alias',
      itempropType: 'alternateName',
      separator: ', ',
      list: aliases
    };
  },
  /**
   * Format person list phones
   * @param {object} person
   * @param {object} router
   * @returns {{itempropType: string, label: string, list: *[], separator: string}|null}
   */
  formatPersonListPhones(person, router) {
    if (!(Array.isArray(person.phones) && person.phones.length)) {
      return null;
    }

    const phones = [];

    for (
      let i = 0;
      i < person.phones.slice(0, pageCms.person_card.count_phone).length;
      i++
    ) {
      const phone = person.phones[i];
      const url = phone.unlink
        ? false
        : getSeoLink({
            router,
            query: router.query,
            params: {
              phone: phone.full
            },
            seoType: 'phone'
          });
      phones.push({
        label: this.formatPhoneNumber(phone.full),
        url
      });
    }

    return {
      label: 'Phone Numbers: ',
      className: 'person__info--phone',
      itempropType: 'telephone',
      separator: ' ',
      list: phones
    };
  },
  /**
   * Format person list emails
   * @param person
   * @returns {{label: string, list: *[], separator: string}|null}
   */
  formatPersonListEmails(person) {
    if (!(Array.isArray(person.emails) && person.emails.length)) {
      return null;
    }

    const emails = [];

    for (
      let i = 0;
      i < person.emails.slice(0, pageCms.person_card.count_email).length;
      i++
    ) {
      emails.push(
        this.maskEmail(person.emails[i].full.toLowerCase(), '', true)
      );
    }

    return {
      label: 'Email Addresses: ',
      className: 'person__info--email',
      separator: ', ',
      list: emails
    };
  },
  /**
   * Creates street string from address object
   * @param {object} address
   * @returns {string|null}
   */
  getStreetStr(address) {
    const validStreet =
      address.street_number || address.street_name || address.street_type;
    if (!validStreet) {
      return null;
    }
    const streetNoAffix = this.setTitleCase(
      `${address.street_number} ${address.street_name} ${address.street_type}`
    ).trim();

    return this.setTitleCase(`${streetNoAffix} ${address.street_affix}`).trim();
  },
  /**
   * Format person list relatives
   * @param {object} person
   * @param {object} router
   * @returns {{itempropType: string, label: string, list: *[], separator: string}|null}
   */
  formatPersonListRelatives(person, router) {
    if (!(Array.isArray(person.relatives) && person.relatives.length)) {
      return null;
    }

    const relativesList = person.relatives.filter(
      relative => relative.firstname && relative.lastname
    );
    const relatives = [];

    for (
      let i = 0;
      i < relativesList.slice(0, pageCms.person_card.count_relatives).length;
      i++
    ) {
      const relative = relativesList[i];
      delete relative.middlename;
      const { firstname, lastname } = relative;
      const firstNameParam = firstname
        .replace(spaceRegEx, '_')
        .replace(dashRegEx, '');
      const lastNameParam = lastname.replace(/ |-/g, '_');
      const url = !relative.unlink
        ? false
        : getSeoLink({
            router: router,
            query: router.query,
            params: {
              firstname: firstNameParam,
              lastname: lastNameParam
            },
            seoType: 'root'
          });

      relatives.push({
        label: this.formatFullName(relative),
        url
      });
    }

    return {
      label: 'Relatives & Associates: ',
      className: 'person__info--relative',
      itempropType: 'relatedTo',
      separator: ' ',
      list: relatives
    };
  },
  /**
   * Get person list social
   * @param person
   * @returns {null|{className: string, label: string, list: string[], separator: string}}
   */
  formatPersonListSocial(person) {
    if (person.has_social_media === 0) {
      return null;
    }

    const profileText = person.has_social_media === 1 ? 'Profile' : 'Profiles';

    return {
      label: 'Social Media: ',
      className: 'person__info--social',
      separator: ', ',
      itempropType: false,
      list: [`${person.has_social_media} ${profileText} Found`]
    };
  },
  /**
   * Get the lists for the person
   * @param {object} person
   * @param {object} router
   * @returns {*[]}
   */
  getPersonLists(person, router) {
    const aliases = this.formatPersonListAliases(person);
    const phones = this.formatPersonListPhones(person, router);
    const emails = this.formatPersonListEmails(person);
    const addresses = formatPersonListAddresses({
      person,
      label: 'Address History:',
      className: 'person__info--address',
      hasItemscope: true,
      separator: '; ',
      maxLocations: pageCms.person_card.count_address,
      maxFullAddresses: pageCms.person_card.count_address_street,
      router
    });
    const relatives = this.formatPersonListRelatives(person, router);
    const social = this.formatPersonListSocial(person);
    const list = [];

    if (addresses) {
      list.push(addresses);
    }

    if (aliases) {
      list.push(aliases);
    }

    if (relatives) {
      list.push(relatives);
    }

    if (phones) {
      list.push(phones);
    }

    if (emails) {
      list.push(emails);
    }

    return list;
  },
  /**
   * Return structured data for ld+json
   *
   * @param {string} addresses
   * @param {string} aliases
   * @param {string} fullName
   * @param {string} phoneNumbers
   * @param {string} profileLink
   * @param {string} relationships
   * @returns {{"@type": string, homeLocation: [], name: string, telephone: [], "@context": string, additionalName: [], url: string, relatedTo: []}}
   */
  getPersonSchema: function(
    addresses,
    aliases,
    fullName,
    phoneNumbers,
    profileLink,
    relationships
  ) {
    relationships = relationships
      ? relationships.map(relative => {
          relative['@type'] = 'Person';
          relative.url =
            relative.profile ?? `https://uspeoplesearch.com${relative.url}`;

          // we don't want props profile and location in ld+json
          if (relative.profile) {
            delete relative.profile;
          }
          if (relative.location) {
            delete relative.location;
          }

          return relative;
        })
      : [];
    aliases = aliases
      ? aliases.split(symbols.general).map(item => item.trim())
      : [];
    phoneNumbers = phoneNumbers
      ? phoneNumbers.split(symbols.general).map(item => item.trim())
      : [];
    addresses = addresses
      ? addresses.split(symbols.address).map(address => {
          const addressParts = address
            .trim()
            .split(symbols.general)
            .map(item => item.trim());
          const noStreet = addressParts.length === 2;
          const stateZip = noStreet ? addressParts[1] : addressParts[2];
          const stateZipParts = stateZip ? stateZip.split(' ') : [];
          return {
            '@type': 'Place',
            address: {
              '@type': 'PostalAddress',
              '@id': '',
              url: '',
              description: '',
              streetAddress: noStreet ? '' : addressParts[0],
              addressLocality: noStreet ? addressParts[0] : addressParts[1],
              addressRegion: stateZipParts[0] ? stateZipParts[0] : '',
              postalCode: stateZipParts[1] ? stateZipParts[1] : ''
            }
          };
        })
      : [];
    return {
      '@context': 'http://schema.org',
      '@type': 'Person',
      url: profileLink,
      name: fullName,
      additionalName: aliases,
      homeLocation: addresses,
      telephone: phoneNumbers,
      relatedTo: relationships
    };
  },
  getCanonicalUrl: function(urlSections) {
    const { firstName, lastName, page, city, state } = urlSections;
    const letterParam = lastName.charAt(0);
    let url = `https://uspeoplesearch.com/${letterParam}/${firstName}-${lastName}`;

    if (state) {
      url += `-${state}`;
    }
    if (city) {
      url += `-${city}`;
    }

    if (page > 1) {
      url += `${page}`;
    }

    return `${url.toLowerCase()}/`;
  },
  /**
   * Return a random selection of relatives with full name and location formatted for display, id and city formatted for url
   *
   * @param {object} people
   * @returns {Array|null}
   */
  getRelativesFormattedForSection: function(people) {
    const setRelatives = [];
    // Get a randomized list of relatives
    const relatives = this.getRandomRelatives(people);
    if (relatives) {
      // Go through each relative object
      for (let i = 0; i < relatives.length; i++) {
        const city = this.setTitleCase(relatives[i].city).replace(/ /g, '-');
        const person = {
          // Add formatted name and location
          firstname: relatives[i].firstname.toLowerCase(),
          lastname: relatives[i].lastname.toLowerCase(),
          fullName: this.formatFullName(relatives[i]),
          fullLocation: `${city}, ${relatives[i].state}`,
          id: relatives[i].id || relatives[i].relative_id,
          // Add dashes instead of spaces in city
          city: city.replace(spaceRegEx, '-').toLowerCase(),
          state: relatives[i].state,
          cid: relatives[i].cid || null
        };
        setRelatives.push(person);
      }
    }

    // Return the list
    return setRelatives;
  },
  /**
   * @param {object} person
   * @param {object} router
   * @returns {string}
   */
  getSEOSchemaLink: function(person, router) {
    const firstName = this.setTitleCase(
      this.parseParamFirstName(person.firstname.toLowerCase())
    )
      .replace(this.spaceRegEx, '_')
      .replace(this.dashRegEx, '');
    const lastName = this.setTitleCase(
      this.parseParamLastName(person.lastname)
    ).replace(this.spaceRegEx, '_');
    let seoLinkType;
    const routerParams = {
      router,
      query: router.query
    };
    let linkParams = {
      firstname: firstName,
      lastname: lastName
    };

    if (person.state) {
      linkParams.state = person.state;
      seoLinkType = 'state';
    }

    if (person.state && person.city) {
      linkParams.city = person.city;
      seoLinkType = 'city';
    }

    if (person.state && person.city && person.id) {
      linkParams.uuid = person.id;
      seoLinkType = 'profile';
    }

    return getSeoLink({
      ...routerParams,
      params: linkParams,
      seoType: seoLinkType
    });
  },
  getSEOMetaData: function(routeName, pageCMS) {
    switch (routeName) {
      case 'seo.city':
        return pageCMS.meta_city;
      case 'seo.state':
        return pageCMS.meta_state;
      case 'seo.phone':
        return pageCMS.meta_phone;
      case 'seo.address':
        return pageCMS.meta_address;
      default:
        return pageCMS.meta_root;
    }
  },
  /**
   * Returns the unformatted title based upon route name
   *
   * @param {string} routeName
   * @param {object} pageCMS
   * @returns {string}
   */
  getSEOMetaTitleText: function(routeName, pageCMS) {
    switch (routeName) {
      case 'seo.city':
        return pageCMS.page_title_city;
      case 'seo.state':
        return pageCMS.page_title_state;
      case 'seo.phone':
        return pageCMS.page_title_phone;
      case 'seo.address':
        return pageCMS.page_title_address;
      default:
        return pageCMS.page_title_root;
    }
  },
  /**
   * Returns formatted meta data for profile page
   *
   * @param {object} pageCMS
   * @param {object} data
   * @returns {*[]}
   */
  getSEOProfileMetaFormatted: function(pageCMS, data) {
    const transformedMeta = [];

    for (let i = 0; i < pageCMS.length; i++) {
      const metaCopy = Object.assign({}, pageCMS[i]);
      const { content } = metaCopy;
      metaCopy.content = NBars.transform(content, data);
      transformedMeta[i] = metaCopy;
    }

    return transformedMeta;
  },
  /**
   * Get the formatted meta data for city pages
   *
   * @param {string} city
   * @param {string} firstName
   * @param {string} lastName
   * @param {object} pageMeta
   * @param {string} page
   * @param {string} state
   * @param {string} stateAbbr
   * @param {string} stateParam
   * @param {string} total
   * @param {string} profile
   * @param {string} dataSumsStr
   * @param {string} siteUrl
   * @param {object} router
   * @param {string} record
   * @returns {*[]}
   */
  getSEOResultsCityMetaFormatted: function(
    city,
    firstName,
    lastName,
    pageMeta,
    page,
    state,
    stateAbbr,
    stateParam,
    total,
    profile,
    dataSumsStr,
    siteUrl,
    router,
    record
  ) {
    const transformedMeta = [];
    const formattedCity = city.includes(' ') ? city.split(' ').join('_') : city;
    const metaSeoCityUrl = getSeoLink({
      router,
      query: router.query,
      params: {
        firstname: firstName,
        lastname: lastName,
        state: stateAbbr,
        city: formattedCity
      },
      seoType: 'city'
    });

    for (let i = 0; i < pageMeta.length; i++) {
      const metaCopy = Object.assign({}, pageMeta[i]);
      const { hid, content } = metaCopy;

      switch (hid) {
        case 'description':
        case 'og:description':
        case 'og:title':
        case 'apple-mobile-web-app-title':
        case 'keywords':
          const formattedState = this.formatName(stateParam.replace(/-/g, ' '));
          metaCopy.content = NBars.transform(content, {
            city: this.formatCity(city),
            firstName,
            lastName,
            page,
            stateAbbr,
            total,
            state: formattedState,
            profile,
            dataSumsStr,
            record
          });
          break;
        case 'url':
        case 'og:url':
          metaCopy.content = NBars.transform(content, {
            url: `${siteUrl}${metaSeoCityUrl}`
          });
          break;
      }

      transformedMeta[i] = metaCopy;
    }

    return transformedMeta;
  },
  /**
   * Returns the transformed string for the city title
   *
   * @param {string} routeName
   * @param {object} pageCMS
   * @param {string} city
   * @param {string} firstName
   * @param {string} lastName
   * @param {string} page
   * @param {string} state
   * @param {string} stateAbbr
   * @param {string} total
   * @param {string} profile
   * @param {string} record
   * @returns {string}
   */
  getSEOResultsCityTitleFormatted: function(
    routeName,
    pageCMS,
    city,
    firstName,
    lastName,
    page,
    state,
    stateAbbr,
    total,
    profile,
    record
  ) {
    return NBars.transform(this.getSEOMetaTitleText(routeName, pageCMS), {
      city: this.formatCity(city),
      firstName,
      lastName,
      page,
      state,
      stateAbbr,
      total,
      profile,
      record
    });
  },
  /**
   * Return formatted meta list for seo root results
   *
   * @param {string} firstName
   * @param {string} lastName
   * @param {array} locationList
   * @param {object} pageMeta
   * @param {string} page
   * @param {number} totalPeople
   * @param {object} metaAlternatives
   * @param  locationsCountSort
   * @param {string} record
   * @param {string} siteUrl
   * @param {object} router
   * @returns {*[]}
   */
  getSEOResultsRootMetaFormatted: function(
    firstName,
    lastName,
    locationList,
    pageMeta,
    page,
    total,
    metaAlternatives,
    locationsCountSort,
    record,
    siteUrl,
    router,
    result
  ) {
    // if there are no other locations, add a period at the end
    const locationsText = !this.otherLocationsCount(locationList)
      ? `${this.formattedLocations(locationsCountSort)}.`
      : this.formattedLocations(locationsCountSort);

    const otherLocationsText = !this.otherLocationsCount(locationList)
      ? ''
      : ` and ${this.otherLocationsCount(locationList)} other states.`;
    const transformedMeta = [];
    const metaRootUrl = getSeoLink({
      router,
      query: router.query,
      params: {
        firstname: firstName,
        lastname: lastName
      },
      seoType: 'root'
    });

    for (let i = 0; i < pageMeta.length; i++) {
      const metaCopy = Object.assign({}, pageMeta[i]);
      const { hid, content } = metaCopy;

      switch (hid) {
        case 'description':
        case 'og:description':
          metaCopy.content = NBars.transform(content, {
            firstName: firstName,
            lastName: lastName,
            page,
            total,
            locations: locationsText,
            otherLocations: otherLocationsText,
            record,
            result
          });
          break;
        case 'og:title':
        case 'apple-mobile-web-app-title':
          metaCopy.content = NBars.transform(content, {
            firstName: firstName,
            lastName: lastName,
            page,
            total,
            record
          });
          break;
        case 'keywords':
          metaCopy.content = NBars.transform(content, {
            firstName,
            lastName
          });
          break;
        case 'url':
        case 'og:url':
          metaCopy.content = NBars.transform(content, {
            url: `${siteUrl}${metaRootUrl}`
          });
      }

      transformedMeta[i] = metaCopy;
    }

    return transformedMeta;
  },
  /**
   * Returns the transformed string for the root title
   *
   * @param {string} routeName
   * @param {object} pageCMS
   * @param {string} firstName
   * @param {string} lastName
   * @param {string} page
   * @param {string} total
   * @returns {string}
   */
  getSEOResultsRootTitleFormatted: function(
    routeName,
    pageCMS,
    firstName,
    lastName,
    page,
    total,
    record
  ) {
    return NBars.transform(this.getSEOMetaTitleText(routeName, pageCMS), {
      firstName,
      lastName,
      page,
      total,
      record
    });
  },
  /**
   * Returns the transformed string for the state title
   *
   * @param {string} routeName
   * @param {object} pageCMS
   * @param {string} firstName
   * @param {string} lastName
   * @param {string} page
   * @param {string} state
   * @param {string} stateAbbr
   * @param {string} total
   * @param {string} profile
   * @param {string} record
   * @returns {string}
   */
  getSEOResultsStateTitleFormatted: function(
    routeName,
    pageCMS,
    firstName,
    lastName,
    page,
    state,
    stateAbbr,
    total,
    profile,
    record
  ) {
    return NBars.transform(this.getSEOMetaTitleText(routeName, pageCMS), {
      firstName,
      lastName,
      page,
      state,
      stateAbbr,
      total,
      profile,
      record
    });
  },
  /**
   * Returns formatted meta list for SEO state results
   *
   * @param {number} cityCount
   * @param {string} firstName
   * @param {string} lastName
   * @param {object} pageMeta
   * @param {string} page
   * @param {string} stateAbbr
   * @param {string} state
   * @param {string} stateParam
   * @param {string} total
   * @param {string} locationsCountSort
   * @param locationList
   * @param {string} profile
   * @param {string} dataSumsStr
   * @param {string} siteUrl
   * @param {object} router
   * @param {string} record
   * @returns {*[]}
   */
  getSEOResultsStateMetaFormatted: function(
    cityCount,
    firstName,
    lastName,
    pageMeta,
    page,
    stateAbbr,
    state,
    stateParam,
    total,
    locationsCountSort,
    locationList,
    profile,
    dataSumsStr,
    siteUrl,
    router,
    record
  ) {
    // if there are no other locations, add a period at the end
    const locationsText = !this.otherLocationsCount(locationList)
      ? `${this.formattedLocations(locationsCountSort)}`
      : this.formattedLocations(locationsCountSort);

    const otherLocationsText = !this.otherLocationsCount(locationList)
      ? ''
      : ` and ${this.otherLocationsCount(locationList)} other cities`;
    const transformedMeta = [];
    const metaSeoStateUrl = getSeoLink({
      router: router,
      query: router.query,
      params: {
        firstname: firstName,
        lastname: lastName,
        state: stateAbbr
      },
      seoType: 'state'
    });

    for (let i = 0; i < pageMeta.length; i++) {
      const metaCopy = Object.assign({}, pageMeta[i]);
      const { hid, content } = metaCopy;

      switch (hid) {
        case 'description':
        case 'og:description':
          metaCopy.content = NBars.transform(content, {
            firstName,
            lastName,
            page,
            stateAbbr,
            state,
            total,
            locations: locationsText,
            otherLocations: otherLocationsText,
            dataSumsStr,
            record: record.toLowerCase()
          });
          break;
        case 'og:title':
        case 'apple-mobile-web-app-title':
        case 'keywords':
          metaCopy.content = NBars.transform(content, {
            firstName,
            lastName,
            page,
            stateAbbr,
            state,
            total,
            profile,
            record
          });
          break;
        case 'url':
        case 'og:url':
          metaCopy.content = NBars.transform(content, {
            url: `${siteUrl}${metaSeoStateUrl}`
          });
      }

      transformedMeta[i] = metaCopy;
    }

    return transformedMeta;
  },
  /**
   * Returns the transformed string for the state title
   *
   * @param {object} opts
   * @param {string} opts.routeName
   * @param {object} opts.pageCMS
   * @param {string} opts.phone
   * @param {string} opts.page
   * @param {string} opts.total
   * @param {string} opts.profile
   * @param {string} opts.record
   * @returns {string}
   */
  getSEOResultsPhoneTitleFormatted: function(opts = {}) {
    return NBars.transform(
      this.getSEOMetaTitleText(opts.routeName, opts.pageCMS),
      {
        phone: opts.phone,
        page: opts.page,
        total: opts.total,
        profile: opts.profile,
        record: opts.record
      }
    );
  },
  /**
   * Returns formatted meta list for SEO phone results
   *
   * @param {object} opts
   * @param {string} opts.phone
   * @param {string} opts.phoneRaw
   * @param {object} opts.pageMeta
   * @param {string} opts.page
   * @param {string} opts.total
   * @param {string} opts.dataSumsStr
   * @param {string} opts.siteUrl
   * @param {object} opts.router
   * @param {string} opts.record
   * @returns {*[]}
   */
  getSEOResultsPhoneMetaFormatted: function(opts = {}) {
    const transformedMeta = [];
    const metaPhoneUrl = getSeoLink({
      router: opts.router,
      query: opts.router.query,
      params: {
        phone: opts.phone.replace(/\D/g, '')
      },
      seoType: 'phone'
    });

    for (let i = 0; i < opts.pageMeta.length; i++) {
      const metaCopy = Object.assign({}, opts.pageMeta[i]);
      const { hid, content } = metaCopy;

      switch (hid) {
        case 'description':
        case 'og:description':
          metaCopy.content = NBars.transform(content, {
            phone: opts.phone,
            page: opts.page,
            total: opts.total,
            dataSumsStr: opts.dataSumsStr,
            record: opts.record
          });
          break;
        case 'og:title':
        case 'apple-mobile-web-app-title':
        case 'keywords':
          metaCopy.content = NBars.transform(content, {
            phone: opts.phone,
            page: opts.page,
            total: opts.total,
            profile: opts.profile,
            record: opts.record
          });
          break;
        case 'url':
        case 'og:url':
          metaCopy.content = NBars.transform(content, {
            url: `${opts.siteUrl}${metaPhoneUrl}`
          });
      }

      transformedMeta[i] = metaCopy;
    }

    return transformedMeta;
  },
  /**
   * Return a string of non-duplicate previous cities
   * @param {array} addresses
   * @param {boolean} includeState
   * @returns {string | (any | string)[]}
   */
  previousCities: function(addresses, includeState = false) {
    const previousCitiesArr = [];
    addresses.forEach(address => {
      const formattedCity = helpers.setTitleCase(address.city);
      const state = address.state;
      const city = includeState ? `${formattedCity}, ${state}` : formattedCity;

      previousCitiesArr.push(city);
    });

    if (!previousCitiesArr.length) {
      return null;
    }

    return previousCitiesArr;
  },
  /**
   *
   * @param {array} results
   * @returns {(any & {count: any})[]}
   */
  updateSeoResultCounts(results) {
    return results.map(item => ({
      ...item,
      count: this.updateCount(item.count)
    }));
  },
  /**
   *
   * @param {number} currentCount
   * @returns {number}
   */
  updateCount(currentCount) {
    if (!currentCount) {
      return;
    }

    if (currentCount > 100) {
      return (currentCount -= 10);
    }

    if (currentCount > 50) {
      return (currentCount -= 5);
    }

    if (currentCount > 27) {
      return (currentCount -= 2);
    }

    return currentCount;
  },
  /**
   * Gets address, phone, email, and social counts for all people displayed on a page
   * @param {array} people
   * @returns {obj}
   */
  getAllPagePeopleDataSums(people) {
    let countsObj = {
      address: 0,
      phone: 0,
      email: 0,
      social: 0
    };
    for (let i = 0; i < people.length; i++) {
      const person = people[i];
      countsObj.address += person.addresses?.length ?? 0;
      countsObj.phone += person.has_phones ?? 0;
      countsObj.email += person.has_emails ?? 0;
      countsObj.social += person.has_social_media ?? 0;
    }
    return countsObj;
  },
  /**
   * Returns formatted link for checkout page
   *
   * @param opts
   * @param {object} opts.person
   * @param {string} opts.landingId
   * @param {boolean} opts.hasEncodeCid
   * @return {string}
   */
  checkoutLink(opts = {}) {
    const { landingId = '23um01', hasEncodeCid = true } = opts;
    const cid = hasEncodeCid
      ? opts.person.cid
      : this.encodeCID(opts.person.cid);
    const firstNameParam = helpers.formatName(opts.person.firstname);
    const lastNameParam = helpers.formatName(opts.person.lastname);
    const stateParam = opts.person.state;
    const cityParam = opts.person.city
      ? helpers
          .formatName(opts.person.city)
          .replace(resultHelper.spaceRegEx, '_')
      : '';

    return `/found/${landingId}/checkout?providerID=${cid}&firstName=${firstNameParam}&lastName=${lastNameParam}&city=${cityParam}&state=${stateParam}&aid=25`;
  },
  /**
   * Return standard ages formatted for select (this is project specific)
   *
   * @returns {[{name: string, value: string}, {name: string, value: string}, {name: string, value: string}, {name: string, value: string}, {name: string, value: string}, null]}
   */
  getAgeOptions: function() {
    return [
      {
        value: '',
        name: 'All Ages'
      },
      {
        value: '18-30',
        name: '18-30'
      },
      {
        value: '31-40',
        name: '31-40'
      },
      {
        value: '41-50',
        name: '41-50'
      },
      {
        value: '51-60',
        name: '51-60'
      },
      {
        value: '61-110',
        name: '61-110'
      }
    ];
  },
  /**
   * Set the profile link for a person (project specific)
   *
   * @param {object} person
   * @returns {string}
   */
  formatProfileLink: function(person) {
    const { city, state, id, firstname, lastname } = person;
    const stateParam = state ? state.toLowerCase() : '';
    const cityParam = city
      ? city.replace(getRegex({ regexType: 'spaceRegEx' }), '_')
      : '';
    const firstNameParam = firstname;
    const lastNameParam = lastname;
    const letterParam = lastNameParam.charAt(0);

    return `/${letterParam}/${firstNameParam}-${lastNameParam}-${stateParam}-${cityParam}/${id}/`.toLowerCase();
  },
  /**
   *
   * @param person
   * @param query
   * @returns {string}
   */
  formatEverFlowLink(person, query) {
    const cid = person.cid.includes('-')
      ? encodeCID({ cid: person.cid })
      : person.cid;
    const firstNameParam = setTitleCase({ text: person.firstname });
    const lastNameParam = setTitleCase({ text: person.lastname });
    const stateParam = person.state ? person.state.toUpperCase() : '';
    const cityParam = person.city
      ? formatCity({ city: person.city }).replace(helpers.spaceRegEx, '+')
      : '';
    const queryObj = {
      ...query,
      sub1: '3',
      sub2: 'USPS',
      providerID: cid,
      firstName: firstNameParam,
      lastName: lastNameParam,
      state: stateParam,
      city: cityParam
    };
    const queryParams = Object.keys(queryObj);
    const queryString = queryParams
      .map(key => key + '=' + queryObj[key])
      .join('&');

    return `https://www.chkppl.com/cmp/LF4LQ/FFX5M/?${queryString}`;
  }
};

export default resultHelper;
