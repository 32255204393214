import getRandomItems from './getRandomItems';
import formatPhoneNumbersAsString from './formatPhoneNumbersAsString';

/**
 * getRandomPhoneNumbers - selects random numbers from an array of people
 * @param {object} opts - an array of people objects
 * @param {array} opts.people - an array of people objects
 * @returns {string|null|[]}
 */
const getRandomPhoneNumbers = (opts = {}) => {
  if (!Array.isArray(opts.people) || !opts.people.length) {
    return [];
  }

  const randomPeople = getRandomItems({
    stuff: opts.people,
    limit: 6
  });
  const selectedPhoneNumbers = [];

  randomPeople.forEach(person => {
    if (Array.isArray(person.phones) && person.phones.length > 0) {
      selectedPhoneNumbers.push(person.phones[0]);
    }
  });

  const phoneList = formatPhoneNumbersAsString({
    phoneList: selectedPhoneNumbers,
    displayCount: 6,
    generalSeparatorSymbol: ', ',
    isRandom: false,
    exposedDigits: 10
  });
  return phoneList || [];
};

export default getRandomPhoneNumbers;
