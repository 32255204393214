<template>
  <section :class="`${prefix}error`">
    <div :class="`${prefix}error__inner`">
      <div :class="`${prefix}error__description`">
        <img
          :class="`${prefix}error__img`"
          :src="errorImg"
          alt="Page not found"
          loading="lazy"
        />
        <a
          href="/"
          :class="`${prefix}error__link`"
          title="People Search"
          v-text="`Back to homepage`"
        />
      </div>
    </div>
  </section>
</template>
<script>
import Meta from '@/assets/js/shared/misc/meta.mjs';
const cms = require(`@/assets/cms/pages/error.json`);
import errorImg from '@/assets/images/error.svg';
export default {
  data() {
    return {
      errorImg
    };
  },
  computed: {
    prefix() {
      return this.$config.prefix || '';
    }
  },
  mounted() {
    this.$bugsnag.notify(new Error('404 Error'));
  },
  head() {
    return {
      title: cms.page_title ?? '404 Error',
      meta: cms.meta ? Meta.convertToNuxtMeta(cms.meta) : []
    };
  }
};
</script>
