import getRegex from '@/assets/js/shared/helpers/getRegex';
import parseParams from '@/assets/js/shared/helpers/parseParams';
import getStates from '@/assets/js/shared/helpers/getStatesList';
import setTitleCase from '@/assets/js/shared/helpers/setTitleCase';
import formatCity from '@/assets/js/shared/helpers/formatCity';

/**
 * Information-Specific Results Redirect Middleware
 * @param route
 * @param redirect
 * @returns {*}
 */

export default function({ route, redirect }) {
  const { fullPath, query, params } = route;
  const queryParams = Object.keys(query);
  const hasValidPage = params.page && params.page <= 10;
  const hasValidQueryParams = queryParams.length;
  let stateParam = params.state ?? null;
  let cityParam = params.city ?? null;
  const firstName = parseParams({
    word: params.firstName,
    splitBy: ['_', ' '],
    parseBy: '_'
  });
  const lastName = parseParams({
    word: params.lastName,
    splitBy: ['_', ' '],
    parseBy: '_'
  });
  let redirectUrl = `/${lastName}/${firstName}/`;

  switch (route.name) {
    case 'seo.phone':
      redirectUrl = `/phone-number/${params.phone}/`;
      break;
    case 'seo.address':
      const redirectStreet = setTitleCase({
        text: params.street.replace('-', ' ')
      }).replace(' ', '-');
      const redirectCity = formatCity({ city: cityParam }).replace(/ |_/g, '-');
      const redirectState = stateParam.toUpperCase();

      redirectUrl = `/location/${redirectState}/${redirectCity}/${redirectStreet}/`;
      break;
    case 'seo.state':
    case 'seo.city':
      stateParam = stateParam;
      const stateList = Object.keys(getStates({ hasTerritories: true })).map(
        state => state
      );
      const hasValidState = stateParam && !!stateList.includes(stateParam);
      if (hasValidState) {
        redirectUrl = redirectUrl + stateParam + '/';
      }
      const hasValidCity = hasValidState && params.city;
      if (hasValidCity) {
        const formattedCity = cityParam.replace(/ |-/g, '_');

        redirectUrl = redirectUrl + formattedCity + '/';
      }
      break;
  }

  if (hasValidPage) {
    redirectUrl = redirectUrl + params.page + '/';
  }

  if (hasValidQueryParams) {
    const queryString = queryParams
      .map(key => key + '=' + query[key])
      .join('&');
    redirectUrl = redirectUrl + '?' + queryString;
  }

  if (fullPath !== redirectUrl) {
    return redirect(301, redirectUrl);
  }
}
