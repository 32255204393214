import setPeopleAges from '@/assets/js/shared/helpers/setPeopleAges';
import setTitleCase from '@/assets/js/shared/helpers/setTitleCase';
import formatCity from '@/assets/js/shared/helpers/formatCity';

/**
 *
 * @param {object} opts
 * @param {array} opts.peopleData
 * @param {array} opts.positions
 * @param {string} opts.defaultValue
 * @returns {{address: string, businessName: string, name: string, position: string}[]}
 */
const selectPeopleByOccupation = (opts = {}) => {
  if (!opts || !opts.peopleData || !opts.positions) {
    return opts?.defaultValue ?? [];
  }
  const people = setPeopleAges({ people: opts.peopleData });

  return people
    .filter(
      person =>
        person.hasOwnProperty('employers') &&
        person.employers.find(
          employer =>
            opts.positions.findIndex(
              occupation =>
                employer.position.toUpperCase().indexOf(occupation) > -1
            ) > -1
        )
    )
    .map(person => {
      const employer = person.employers.find(
        employer =>
          opts.positions.findIndex(
            occupation =>
              employer.position.toUpperCase().indexOf(occupation) > -1
          ) > -1
      );
      const address =
        person.addresses?.find(address => address.current_address) ??
        person.addresses[0];
      return {
        name: setTitleCase({
          text: `${person.firstname}${
            person.middlename ? ` ${person.middlename} ` : ' '
          }${person.lastname}`
        }),
        address: `${formatCity({ city: address.city })}, ${address.state}`,
        businessName: setTitleCase({ text: employer.businessname }),
        position: setTitleCase({ text: employer.position })
      };
    });
};

export default selectPeopleByOccupation;
