import setCrimes from './setCrimes';
/**
 * getCrimeData - iterates through data to find crime info, then runs it through formatter
 * @param {object} opts - the existing formatted payload
 * @param {object} opts.payload - the existing formatted payload
 * @returns {{criminalRecordsSexOffenderCount, crimes: ([]|*), limitedCrimes}}
 */
const getCrimeData = (opts = {}) => {
  if (!opts || !opts.payload || !opts.payload.peopleResponse.length) {
    return {};
  }

  const filterCrime = crimeArray => {
    // handles filtering of crime data
    if (!Array.isArray(crimeArray) || crimeArray.length === 0) {
      return [];
    }

    return crimeArray.filter(item => item?.crimes?.length > 0);
  };

  const crimes = filterCrime(opts.payload.peopleResponse);
  const { limitedCrimes, criminalRecordsSexOffenderCount } = setCrimes({
    crimes: crimes,
    limit: 4,
    onlyCrimes: false
  });

  return {
    crimes,
    limitedCrimes,
    criminalRecordsSexOffenderCount
  };
};

export default getCrimeData;
